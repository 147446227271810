<template>
    <!-- # Attacker -->
    <v-sheet
        :class="!$vuetify.breakpoint.mobile? 'rounded-12':''"
        :style="!$vuetify.breakpoint.mobile?
            {
                width: '362px',
                height: '780px',
                border: '1px solid #ddd',
                boxShadow: '0 0 6px 0 #e1e1e1'
            }
            : 
            {
                height: '100%'
            }
        "
    >
        <!-- # 상단 -->
        <v-sheet
            height="60"
            class="d-flex align-center rounded-t-12"
            style="border-bottom:1px solid #ddd;"
        >
            <!-- # 포켓몬 기본 정보 -->
            <div
                class="flex-grow-1 d-flex align-center"
            >
                <div
                    class="ml-3 mr-3 mb-2px"
                    style="width:36px; height:36px;"
                >
                    <v-img
                        v-if="attacker.sprite"
                        :src="attacker.sprite"
                    ></v-img>
                    <v-img
                        v-else
                        src="@/assets/icons/default_attacker_sprite.png"
                    ></v-img>
                </div>
                <div>
                    <div
                        class="d-flex"
                        :style="attacker.name && loading == false? 'cursor:pointer;':''"
                        @click="attacker.name && loading == false? $emit('open_stat') : ''"
                    >
                        <p
                            class="mb-0 font-weight-medium alter_black--text mr-6px"
                            style="font-size:12px; letter-spacing:-1px;"
                        >
                            <span v-if="!attacker.name && loading == false">스마트누오</span>
                            {{attacker.types[0]? attacker.types[0] : (attacker.name? '타입 없음' : '')}} <span v-if="attacker.types[1]">/</span> {{attacker.types[1]}}
                        </p>
                        <v-icon
                            v-if="attacker.name && loading == false"
                            size="14"
                            color="primary lighten-2"
                        >
                            mdi-pencil
                        </v-icon>
                        <div
                            v-if="attacker.name && loading == false && $vuetify.breakpoint.mobile"
                            style="width:14px; height:14px; cursor:pointer; margin-top:2px;"
                            @click.stop="attacker.name && loading == false? $emit('switch') : ''"
                        >
                            <v-img
                                contain
                                src="@/assets/icons/swap.png"
                            ></v-img>
                        </div>
                    </div>
                    <p
                        class="mb-0 font-weight-medium alter_grey--text"
                        style="margin-top:-1px; font-size:8px;"
                        :style="attacker.name && loading == false? 'cursor:pointer;':''"
                        @click="attacker.name && loading == false? $emit('open_stat') : ''"
                    >
                        H{{attacker.stats.hp}} A{{attacker.stats.attack}} B{{attacker.stats.defense}} C{{attacker.stats.special_attack}} D{{attacker.stats.special_defense}} S{{attacker.stats.speed}}
                    </p>
                    <p
                        class="mb-0 font-weight-regular alter_grey--text"
                        style="margin-top:-1px; font-size:8px; border-bottom:1px solid #8FC31F;"
                    >
                        시즌 00 싱글 00위 더블 00위
                    </p>
                </div>
            </div>

            <v-spacer></v-spacer>

            <!-- # 계산 버튼 -->
            <v-btn
                v-if="$vuetify.breakpoint.mobile"
                depressed
                small
                width="64"
                height="25"
                class="rounded-lg ml-2"
                style="background:#A1A4BA; font-size:14px; font-weight:300 !important;"
                dark
                @click="$emit('open_caculate')"
            >
                계산
            </v-btn>

            <!-- 검 -->
            <div
                class="ml-2 mr-3"
                style="width:24px; height:25px;"
                @click="$vuetify.breakpoint.mobile? toggleMobileTab2() :''"
            >
                <v-img
                    contain
                    width="24"
                    height="25"
                    src="@/assets/icons/attacker.png"
                ></v-img>
            </div>

            <!-- 스피드 -->
            <div
                :class="$store.state.is_admin? 'mr-1' : 'mr-4'"
                style="width:14px; height:25px;"
                @click="$vuetify.breakpoint.mobile? toggleMobileTab() : $store.commit('update_is_speed', true)"
            >
                <v-img
                    contain
                    width="14"
                    height="25"
                    src="@/assets/icons/speed_off.png"
                ></v-img>
            </div>

            <!-- 도감 -->
            <div
                v-if="$store.state.is_admin"
                class="ml-6px mr-3"
                style="width:31px; height:25px;"
                @click="$vuetify.breakpoint.mobile? toggleMobileTab3() : $store.commit('update_is_docs', !$store.state.is_docs)"
            >
                <v-img
                    contain
                    width="31"
                    height="25"
                    src="@/assets/icons/docs.png"
                ></v-img>
            </div>
        </v-sheet>

        <!-- # 1단 -->
        <v-sheet
            height="144"
            style="border-bottom:1px solid #ddd; padding:10px;"
        >
            <!-- # 1줄 -->
            <div
                class="d-flex align-center"
                style="height:64px;"
            >
                <!-- # 포켓몬 이름 -->
                <v-autocomplete
                    ref="pokemonAutocomplete"
                    class="rounded-lg shrink"
                    style="width:195px;"
                    label="포켓몬 이름"
                    placeholder="포켓몬 선택"
                    persistent-placeholder
                    dense
                    hide-details
                    item-value="en"
                    item-text="kr"
                    :items="pokemon_list"
                    v-model="attacker.name"
                    outlined
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                    }"
                    @change="loadAttacker()"
                    @keydown.enter.native="(e) => handleEnter('pokemon', e)"
                ></v-autocomplete>

                <div
                    v-if="!loading"
                    class="d-flex align-center"
                >
                    <!-- # 테라스탈 -->
                    <div
                        v-if="attacker.terastal"
                        class="ml-2"
                        style="width:26px; height:26px; cursor:pointer;"
                        @click="attacker.terastal = !attacker.terastal"
                    >
                        <v-img
                            src="@/assets/icons/terastal_on.png"
                        ></v-img>
                    </div>
                    <div
                        v-else
                        class="ml-2"
                        style="width:26px; height:26px; cursor:pointer;"
                        @click="attacker.terastal = !attacker.terastal"
                    >
                        <v-img
                            src="@/assets/icons/terastal_off.png"
                        ></v-img>
                    </div>

                    <!-- # 스텔라 -->
                    <div
                        v-if="attacker.stellar"
                        class="ml-2"
                        style="width:26px; height:26px; cursor:pointer;"
                        @click="attacker.stellar = !attacker.stellar"
                    >
                        <v-img
                            src="@/assets/icons/stellar_on.png"
                        ></v-img>
                    </div>
                    <div
                        v-else
                        class="ml-2"
                        style="width:26px; height:26px; cursor:pointer;"
                        @click="attacker.stellar = !attacker.stellar"
                    >
                        <v-img
                            src="@/assets/icons/stellar_off.png"
                        ></v-img>
                    </div>
                </div>
            </div>

            <!-- # 2줄 -->
            <div
                class="d-flex align-center"
                style="height:64px;"
            >
                <!-- # 기술명 -->
                <v-autocomplete
                    ref="moveAutocomplete"
                    class="rounded-lg shrink"
                    style="width:195px;"
                    label="기술명"
                    placeholder="기술 선택"
                    persistent-placeholder
                    dense
                    hide-details
                    item-value="en"
                    item-text="kr"
                    :items="move_list"
                    v-model="attacker.move.name"
                    outlined
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                    }"
                    @change="loadMove()"
                    @keydown.enter.native="(e) => handleEnter('move', e)"
                ></v-autocomplete>

                <!-- # 타입 -->
                <v-select
                    class="rounded-lg shrink"
                    style="width:82px; margin-left:5px;"
                    label="타입"
                    placeholder="타입"
                    persistent-placeholder
                    dense
                    hide-details
                    :items="type_list"
                    v-model="attacker.move.type"
                    outlined
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                    }"
                ></v-select>

                <!-- # 위력 -->
                <div
                    class="center_label"
                    style="margin-left:8px;"
                >
                    <v-text-field
                        class="rounded-lg shrink"
                        style="width:50px;"
                        label="위력"
                        dense
                        hide-details
                        v-model="attacker.move.power"
                        outlined
                    ></v-text-field>
                </div>
            </div>
        </v-sheet>

        <!-- # 2단 -->
        <v-sheet
            height="234"
            style="border-bottom:1px solid #ddd; padding:10px;"
        >
            <!-- # 1줄 -->
            <div
                class="d-flex align-center"
                style="height:28px;"
            >
                <p
                    class="mb-0"
                    style="font-size:14px; font-weight: 500; letter-spacing:-1px;"
                >
                    공격 수치
                </p>
                <p
                    class="mt-2 mb-0 ml-2"
                    style="font-size:8px;"
                >
                    *해당값은 기술타입에 따라 공격 / 특수공격이 적용됩니다
                </p>
            </div>

            <!-- # 2줄 -->
            <div
                class="d-flex align-center mt-1 center_label"
                style="height:58px;"
            >
                <!-- # 노력치 -->
                <div
                    class="d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:50px;"
                        label="노력치"
                        dense
                        hide-details
                        v-model="attacker.effort"
                        outlined
                        type="number"
                        min="0"
                        max="252"
                        placeholder="0"
                        :persistent-placeholder="true"
                        @focus="$event.target.select()"
                    ></v-text-field>

                    <!-- # MAX & MIN -->
                    <div
                        class="ml-5px"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                :color="attacker.effort == 252? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                type="number"
                                @click="attacker.effort = 252"
                            >
                                MAX
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                :color="attacker.effort == 0? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="attacker.effort = 0"
                            >
                                MIN
                            </v-btn>
                        </div>
                    </div>

                    <!-- # + & - -->
                    <div
                        class="ml-1"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="attacker.effort < 252? updateInput('effort', 4) : ''"
                            >
                                ＋
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="attacker.effort > 0? updateInput('effort', -4) : ''"
                            >
                                －
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- # 개체값 -->
                <div
                    class="ml-2 d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:50px;"
                        label="개체값"
                        dense
                        hide-details
                        v-model="attacker.individual_value"
                        outlined
                        type="number"
                        min="0"
                        max="31"
                        placeholder="0"
                        :persistent-placeholder="true"
                        @focus="$event.target.select()"
                    ></v-text-field>

                    <!-- # MAX & MIN -->
                    <div
                        class="ml-5px"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                :color="attacker.individual_value == 31? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="attacker.individual_value = 31"
                            >
                                MAX
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                :color="attacker.individual_value == 0? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="attacker.individual_value = 0"
                            >
                                MIN
                            </v-btn>
                        </div>
                    </div>

                    <!-- # + & - -->
                    <div
                        class="ml-1"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="attacker.individual_value < 31? updateInput('individual_value', 1) : ''"
                            >
                                ＋
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="attacker.individual_value > 0? updateInput('individual_value', -1) : ''"
                            >
                                －
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- ! 실수값 -->
                <div
                    class="ml-2 d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:50px;"
                        label="실수값"
                        dense
                        hide-details
                        v-model="attack_modifier"
                        outlined
                        readonly
                    ></v-text-field>
                </div>
            </div>

            <!-- # 3줄 -->
            <div
                class="d-flex align-center mt-1 center_label"
                style="height:58px;"
            >
                <!-- # 랭크업 -->
                <div
                    class="d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:50px;"
                        label="랭크업"
                        dense
                        hide-details
                        v-model="attacker.rankup"
                        outlined
                        type="number"
                        min="-6"
                        max="6"
                        placeholder="0"
                        :persistent-placeholder="true"
                        @focus="$event.target.select()"
                    ></v-text-field>

                    <!-- # MAX & MIN -->
                    <div
                        class="ml-5px"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                :color="attacker.rankup == 6? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="attacker.rankup = 6"
                            >
                                MAX
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                :color="attacker.rankup == -6? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="attacker.rankup = -6"
                            >
                                MIN
                            </v-btn>
                        </div>
                    </div>

                    <!-- # + & - -->
                    <div
                        class="ml-1"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="attacker.rankup < 6? updateInput('rankup', 1) : ''"
                            >
                                ＋
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="attacker.rankup > -6? updateInput('rankup', -1) : ''"
                            >
                                －
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- # 레벨 & 결정력 -->
                <div
                    class="ml-2 d-flex align-center"
                >
                    <!-- # 레벨 -->
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:50px; min-width:50px;"
                        label="레벨"
                        dense
                        hide-details
                        v-model="attacker.level"
                        outlined
                        type="number"
                        min="1"
                        max="100"
                        placeholder="0"
                        :persistent-placeholder="true"
                        @focus="$event.target.select()"
                    ></v-text-field>

                    <div
                        class="ml-5px flex-1"
                    >
                        <!-- # 결정력 -->
                        <v-text-field
                            class="rounded-lg no-spinners"
                            label="결정력"
                            dense
                            hide-details
                            v-model="potential"
                            outlined
                            readonly
                        ></v-text-field>
                    </div>
                </div>
            </div>

            <!-- # 4줄 -->
            <div
                class="d-flex align-center mt-1"
                style="height:58px;"
            >
                <!-- # 성격 보정 -->
                <div
                    class="d-flex align-center center_label"
                    style="position:relative;"
                >
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:195px;"
                        label="성격 보정"
                        placeholder=""
                        persistent-placeholder
                        dense
                        hide-details
                        outlined
                    ></v-text-field>

                    <div
                        style="position:absolute; width:160px; left:50%; transform:translateX(-50%);"
                        class="text_button d-flex justify-space-between align-center"
                    >
                        <v-btn
                            min-width="40"
                            width="40"
                            text
                            small
                            v-ripple="false"
                            style="background:none;"
                            class="font-weight-regular px-0"
                            :color="attacker.personality == 0.9? '#E4007F':''"
                            @click="attacker.personality = 0.9"
                        >
                            <span style="font-size:8px;">x</span>0.9
                        </v-btn>

                        <div style="width:1px; height:14px; background:#969696;"></div>

                        <v-btn
                            min-width="40"
                            width="40"
                            text
                            small
                            v-ripple="false"
                            style="background:none;"
                            class="font-weight-regular px-0"
                            :color="attacker.personality == 1.0? '#E4007F':''"
                            @click="attacker.personality = 1.0"
                        >
                            <span style="font-size:8px;">x</span>1.0
                        </v-btn>

                        <div style="width:1px; height:14px; background:#969696;"></div>

                        <v-btn
                            min-width="40"
                            width="40"
                            text
                            small
                            v-ripple="false"
                            style="background:none;"
                            class="font-weight-regular px-0"
                            :color="attacker.personality == 1.1? '#E4007F':''"
                            @click="attacker.personality = 1.1"
                        >
                            <span style="font-size:8px;">x</span>1.1
                        </v-btn>
                    </div>
                </div>

                <!-- # 배수 & 상태이상 -->
                <div
                    class="ml-5px d-flex align-center"
                >
                    <!-- # 배수 -->
                    <div
                        class="center_label"
                    >
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:54px; min-width:54px;"
                            label="배수"
                            dense
                            hide-details
                            v-model="attacker.multiple"
                            outlined
                            type="number"
                            :min="0.1"
                            :max="10"
                            step="0.01"
                            :precision="2"
                            @change="restrictDecimal"
                            :key="componentKey"
                        ></v-text-field>
                    </div>

                    <!-- # 상태이상 -->
                    <div
                        class="ml-5px flex-1 center_label"
                    >
                        <v-select
                            class="rounded-lg"
                            label="상태이상"
                            placeholder="선택"
                            persistent-placeholder
                            dense
                            hide-details
                            :items="cc_list"
                            v-model="attacker.cc"
                            outlined
                            clearable
                            :menu-props="{
                                offsetY: true,
                                maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                            }"
                        ></v-select>
                    </div>
                </div>
            </div>
        </v-sheet>

        <!-- # 3단 -->
        <v-sheet
            style="padding:10px;"
        >
            <!-- # 1줄 -->
            <div
                class="d-flex align-center"
                style="height:64px;"
            >
                <!-- # 특성 -->
                <v-autocomplete
                    ref="abilityAutocomplete"
                    class="rounded-lg"
                    style="width:195px; min-width:195px;"
                    label="특성"
                    placeholder="특성 선택"
                    persistent-placeholder
                    dense
                    hide-details
                    :items="abilities_list"
                    v-model="attacker.ability"
                    outlined
                    clearable
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 152 : 216
                    }"
                    @keydown.enter.native="(e) => handleEnter('ability', e)"
                ></v-autocomplete>

                <!-- # 날씨 -->
                <div
                    class="ml-5px flex-1"
                >
                    <v-select
                        class="rounded-lg"
                        label="날씨"
                        placeholder="날씨 선택"
                        persistent-placeholder
                        dense
                        hide-details
                        :items="weather_list"
                        v-model="attacker.weather"
                        outlined
                        clearable
                        :menu-props="{
                            offsetY: true,
                            maxHeight: $vuetify.breakpoint.mobile ? 152 : 216
                        }"
                    ></v-select>
                </div>
            </div>

            <!-- # 2줄 -->
            <div
                class="d-flex align-center"
                style="height:64px;"
            >
                <!-- # 도구 -->
                <v-autocomplete
                    ref="equipmentAutocomplete"
                    class="rounded-lg"
                    style="width:195px; min-width:195px;"
                    label="도구"
                    placeholder="도구 선택"
                    persistent-placeholder
                    dense
                    hide-details
                    :items="equipment_list"
                    v-model="attacker.equipment"
                    outlined
                    clearable
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 152 : 216
                    }"
                    @keydown.enter.native="(e) => handleEnter('equipment', e)"
                ></v-autocomplete>

                <!-- # 필드 -->
                <div
                    class="ml-5px flex-1"
                >
                    <v-select
                        class="rounded-lg"
                        label="필드"
                        placeholder="필드 선택"
                        persistent-placeholder
                        dense
                        hide-details
                        :items="field_list"
                        v-model="attacker.field"
                        outlined
                        clearable
                        :menu-props="{
                            offsetY: true,
                            maxHeight: $vuetify.breakpoint.mobile ? 152 : 216
                        }"
                    ></v-select>
                </div>
            </div>

            <!-- # 서포트 -->
            <div>
                <div
                    class="d-flex align-center"
                >
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:25%;"
                        label="급소"
                        dense
                        hide-details
                        v-model="attacker.support.gebso"
                    ></v-checkbox>
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:25%;"
                        label="충전"
                        dense
                        hide-details
                        v-model="attacker.support.charge"
                    ></v-checkbox>
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:25%;"
                        label="도우미"
                        dense
                        hide-details
                        v-model="attacker.support.helper"
                    ></v-checkbox>
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:25%;"
                        label="배터리"
                        dense
                        hide-details
                        v-model="attacker.support.battery"
                    ></v-checkbox>
                </div>

                <div
                    class="d-flex align-center"
                >
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="파워스폿"
                        dense
                        hide-details
                        v-model="attacker.support.power_spot"
                    ></v-checkbox>
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="프렌드가드"
                        dense
                        hide-details
                        v-model="attacker.support.friend_guard"
                    ></v-checkbox>
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="플라워기프트"
                        dense
                        hide-details
                        v-model="attacker.support.flower_gift"
                    ></v-checkbox>
                </div>

                <div
                    class="d-flex align-center"
                >
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="페어리오라"
                        dense
                        hide-details
                        v-model="attacker.support.fairy_aura"
                    ></v-checkbox>
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="다크오라"
                        dense
                        hide-details
                        v-model="attacker.support.dark_aura"
                    ></v-checkbox>
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="오라브레이크"
                        dense
                        hide-details
                        v-model="attacker.support.aura_break"
                    ></v-checkbox>
                </div>

                <div
                    class="d-flex align-center"
                >
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="재앙의목간"
                        dense
                        hide-details
                        v-model="attacker.support.disaster_plate"
                    ></v-checkbox>
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="재앙의검"
                        dense
                        hide-details
                        v-model="attacker.support.disaster_sword"
                    ></v-checkbox>
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="재앙의그릇"
                        dense
                        hide-details
                        v-model="attacker.support.disaster_dish"
                    ></v-checkbox>
                </div>

                <div
                    class="d-flex align-center"
                >
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="재앙의구슬"
                        dense
                        hide-details
                        v-model="attacker.support.disaster_orb"
                    ></v-checkbox>
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="강철정신"
                        dense
                        hide-details
                        v-model="attacker.support.steel_spirit"
                    ></v-checkbox>
                    <div
                        class="flex-grow-1"
                        style="width:33%;"
                    ></div>
                </div>
            </div>
        </v-sheet>
    </v-sheet>
</template>
<script>
import common from '@/data/common.json'
import attacker from '@/data/attacker.json'
import condition from '@/data/condition.json'

export default {
    props: [
        "defender",
        "reset_key"
    ],

    data: () => ({
        // 펀치기술
        punch_list: condition.punch_list,

        // 반동데미지 기술
        rebound_list: condition.rebound_list,

        // 접촉기술 > 물리 기술이지만 접촉기술이 아닌 경우
        touch_but_no_physical_list: condition.touch_but_no_physical_list,

        // 접촉기술 > 특수기술이지만 접촉 기술인 경우
        touch_but_special_list: condition.touch_but_special_list,

        // 베기 기술
        cut_list: condition.cut_list,

        // 무는 기술
        bite_list: condition.bite_list,

        // 소리 기술
        sound_list: condition.sound_list,

        // 파동 기술
        wave_list: condition.wave_list,

        // 바람 기술
        wind_list: condition.wind_list,

        // 구슬/폭탄 기술
        bomb_list: condition.bomb_list,

        // 교체 기술
        switch_list: condition.switch_list,

        // 자폭 기술
        suicide_list: condition.suicide_list,

        // 선공 기술
        strike_first_list: condition.strike_first_list,

        // 타입 목록
        type_list: common.type_list,

        // 포켓몬 목록
        pokemon_list: [],

        // Attacker > 기술
        move_list: [],

        // Attacker > 특성
        abilities_list: attacker.abilities_list,

        // Attacker > 도구
        equipment_list: attacker.equipment_list,

        // Attacker > 상태이상
        cc_list: attacker.cc_list,

        // Attacker > 날씨
        weather_list: attacker.weather_list,

        // Attacker > 필드
        field_list: attacker.field_list,
        
        // # Attacker
        attacker: {
            // 이름
            name: "",

            // 이미지
            sprite: null,

            // 타입
            types: [],

            // 스탯
            stats: {
                // H
                hp: 0,

                // A
                attack: 0,

                // B
                defense: 0,

                // C
                special_attack: 0,

                // D
                special_defense: 0,

                // S
                speed: 0,
            },

            // 테라스탈
            terastal: false,

            // 스텔라
            stellar: false,

            // 기술
            move: {
                // 기술명
                name: "",

                // 기술 타입
                type: "",

                // 위력
                power: 0,

                // 데미지 유형
                damage_class: ""
            },

            // 노력치
            effort: 252,

            // 개체값
            individual_value: 31,

            // 랭크업
            rankup: 0,

            // 공격력 (기술의 damage_class 따라 스탯 attack, special_attack를 대입)
            damage: 0,

            // 성격 보정
            personality: 1.0,

            // 레벨
            level: 50,

            // 배수
            multiple: 1,

            // 특성
            ability: "",

            // 도구
            equipment: "",

            // 상태이상 (없음 value 값 임시 지정)
            cc: "",

            // 날씨
            weather: "",

            // 필드
            field: "",

            // 서포트
            support: {
                // 급소
                gebso: false,

                // 충전
                charge: false,

                // 도우미
                helper: false,

                // 배터리
                battery: false,

                // 파워스톤
                power_spot: false,

                // 프렌드가드
                friend_guard: false,

                // 플라워기프트
                flower_gift: false,

                // 페어리오라
                fairy_aura: false,

                // 다크오라
                dark_aura: false,

                // 오라브레이크
                aura_break: false,

                // 재앙의목간
                disaster_plate: false,

                // 재앙의검
                disaster_sword: false,

                // 재앙의그릇
                disaster_dish: false,

                // 재앙의구슬
                disaster_orb: false,

                // 강철정신
                steel_spirit: false,
            }
        },

        loading: false,
        componentKey: 0,
    }),

    watch: {
        reset_key() {
            this.attacker = {
                name: this.attacker.name,
                sprite: this.attacker.sprite,
                types: this.attacker.types,
                stats: this.attacker.stats,
                terastal: false,
                stellar: false,
                move: {
                    name: "",
                    type: "",
                    power: 0,
                    damage_class: ""
                },
                effort: 252,
                individual_value: 31,
                rankup: 0,
                damage: 0,
                personality: 1.0,
                level: 50,
                multiple: 1,
                ability: "",
                equipment: "",
                cc: "",
                weather: "",
                field: "",
                support: {
                    gebso: false,
                    charge: false,
                    helper: false,
                    battery: false,
                    power_spot: false,
                    friend_guard: false,
                    flower_gift: false,
                    fairy_aura: false,
                    dark_aura: false,
                    aura_break: false,
                    disaster_plate: false,
                    disaster_sword: false,
                    disaster_dish: false,
                    disaster_orb: false,
                    steel_spirit: false,
                }
            }
        },

        "attacker.effort"(newVal) {
            this.validateInput('effort', newVal, 0, 252, 0)
        },
        "attacker.individual_value"(newVal) {
            this.validateInput('individual_value', newVal, 0, 31, 0)
        },
        "attacker.rankup"(newVal) {
            this.validateInput('rankup', newVal, -6, 6, 0)
        },
        "attacker.level"(newVal) {
            this.validateInput('level', newVal, 1, 100, 1)
        },

        // 테라스탈, 스텔라 동시선택 불가 처리
        "attacker.terastal"(newVal) {
            if (newVal) {
                this.attacker.stellar = false
            }
        },
        "attacker.stellar"(newVal) {
            if (newVal) {
                this.attacker.terastal = false
            } 
        },

        // attacker 변경시 Emit
        attacker: {
            handler(newValue, oldValue) {
                // 공격력
                this.attacker.damage = this.attacker.move.damage_class == "physical"? parseInt(this.attacker.stats.attack) : parseInt(this.attacker.stats.special_attack)

                this.$emit('attacker_updated', newValue)
                this.$store.commit('attacker_updated', this.attacker)
            },
            deep: true
        },

        // 특성 변경시 자동 선택
        "attacker.ability"(newVal) {
            if (newVal == "진홍빛고동") {
                this.attacker.weather = "쾌청"
            } 
            else if (newVal == "하드론엔진") {
                this.attacker.field = "일렉트릭필드"
            }
            else if (newVal == "노말스킨") {
                this.attacker.move.type = "노말"
            }
            else if (newVal == "스카이스킨") {
                this.attacker.move.type = "비행"
            }
            else if (newVal == "일렉트릭스킨") {
                this.attacker.move.type = "전기"
            }
            else if (newVal == "프리즈스킨") {
                this.attacker.move.type = "얼음"
            }
            else if (newVal == "페어리스킨") {
                this.attacker.move.type = "페어리"
            }
            else if (newVal == "전기로바꾸기") {
                this.attacker.support.charge = true
            }
            else if (newVal == "풍력발전") {
                this.attacker.support.charge = true
            }
        },
    },

    computed: {
        // 공격 실수값
        attack_modifier(){
            // (공격력 + 개체값/2 + 노력치/8 + 5) * 성격 보정 * 랭크업 보정
            // return Math.floor(
            //     (this.attacker.damage + this.attacker.individual_value / 2 + this.attacker.effort / 8 + 5) 
            //     * this.attacker.personality
            // )

            // [{(종족값x2)+개체값+(노력치/4)}x레벨/100+5]x성격보정
            return Math.floor(
                Math.floor(
                    (
                        this.attacker.damage * 2
                        + this.attacker.individual_value
                        + this.attacker.effort / 4
                    ) 
                    * parseInt(this.attacker.level) / 100
                    + 5
                )
                * this.attacker.personality
            )
        },

        // 위력
        caculated_power(){
            let power = 1
            power = this.attacker.move.power

            // # 위력
            // 도우미
            // 기술위력
            // 도구보정
            // 충전
            // 특성보정
            // 상대특성보정
            // 서포트
            
            // # 도우미
            if(this.attacker.support.helper){
                power = power * 1.5
            }

            // # 기술위력 (input 값)

            // # 도구 보정
            if(this.attacker.equipment == "힘의머리띠" && this.attacker.move.damage_class == "physical"){
                power = power * 1.1
            }
            else if(this.attacker.equipment == "박식안경" && this.attacker.move.damage_class != "physical"){
                power = power * 1.1
            }
            else if(this.attacker.equipment == "실크스카프" && this.attacker.move.type == "노말"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "목탄" && this.attacker.move.type == "불꽃"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "신비의물방울" && this.attacker.move.type == "물"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "기적의씨" && this.attacker.move.type == "풀"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "자석" && this.attacker.move.type == "전기"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "녹지않는얼음" && this.attacker.move.type == "얼음"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "검은띠" && this.attacker.move.type == "격투"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "독바늘" && this.attacker.move.type == "독"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "부드러운모래" && this.attacker.move.type == "땅"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "예리한부리" && this.attacker.move.type == "비행"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "휘어진스푼" && this.attacker.move.type == "에스퍼"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "은빛가루" && this.attacker.move.type == "벌레"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "딱딱한돌" && this.attacker.move.type == "바위"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "저주의부적" && this.attacker.move.type == "고스트"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "용의이빨" && this.attacker.move.type == "드래곤"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "검은안경" && this.attacker.move.type == "악"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "금속코트" && this.attacker.move.type == "강철"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "요정의깃털" && this.attacker.move.type == "페어리"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "주얼" && this.attacker.move.type == "노말"){
                power = power * 1.3
            }
            else if(this.attacker.equipment == "플레이트"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "마음의물방울" && (this.attacker.move.type == "에스퍼" || this.attacker.move.type == "드래곤")){
                power = power * 1.2
            }

            else if(this.attacker.equipment == "펀치글러브" && this.punch_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.2
            }

            else if(this.attacker.equipment == "화덕의가면" && this.attacker.move.type == "불꽃"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "주춧돌의가면" && this.attacker.move.type == "바위"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "우물의가면" && this.attacker.move.type == "물"){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "금강옥" && (this.attacker.move.type == "강철" || this.attacker.move.type == "드래곤")){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "백옥" && (this.attacker.move.type == "물" || this.attacker.move.type == "드래곤")){
                power = power * 1.2
            }
            else if(this.attacker.equipment == "백금옥" && (this.attacker.move.type == "고스트" || this.attacker.move.type == "드래곤")){
                power = power * 1.2
            }

            // # 충전
            if(this.attacker.support.charge && this.attacker.move.type == "전기"){
                power = power * 2
            }
            else if(this.attacker.support.charge && this.attacker.move.type == "전기" && this.attacker.ability == "전기로바꾸기"){
                power = power * 2
            }
            else if(this.attacker.support.charge  && this.attacker.move.type == "전기" && this.attacker.ability == "풍력발전"){
                power = power * 2
            }

            // console.log(this.punch_list)
            // console.log(this.translateMove(this.attacker.move.name))
            // console.log(this.punch_list.includes(this.translateMove(this.attacker.move.name)))

            // # 특성 보정
            if(this.attacker.ability == "투쟁심(성별같음)"){
                power = power * 1.25
            }
            else if(this.attacker.ability == "투쟁심(성별다름)"){
                power = power * 0.75
            }
            else if(this.attacker.ability == "이판사판" && this.rebound_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.2
            }
            else if(this.attacker.ability == "철주먹" && this.punch_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.2
            }
            else if(this.attacker.ability == "맹화" && this.attacker.move.type == "불꽃"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "급류" && this.attacker.move.type == "물"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "심록" && this.attacker.move.type == "풀"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "벌레의알림" && this.attacker.move.type == "벌레"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "테크니션" && this.attacker.move.power <= 60){
                power = power * 1.5
            }
            // 타입상성에서 처리
            // else if(
            //     this.attacker.ability == "심안"
            //     && this.defender.type.includes("고스트")
            //     && (this.attacker.move.type == "노말" || this.attacker.move.type == "격투"))
            // {
            //     // 원래 타입상성1 계산으로 0인데 이 조건 성립시 1로 변경
            // }
            // 타입상성에서 처리
            // else if(
            //     this.attacker.ability == "배짱"
            //     && this.defender.type.includes("고스트")
            //     && (this.attacker.move.type == "노말" || this.attacker.move.type == "격투"))
            // {
            //     // 원래 타입상성1 계산으로 0인데 이 조건 성립시 1로 변경
            // }
            else if(this.attacker.ability == "순수한힘" && this.attacker.move.damage_class == "physical"){
                power = power * 2
            }
            else if(this.attacker.ability == "천하장사" && this.attacker.move.damage_class == "physical"){
                power = power * 2
            }
            else if(this.attacker.ability == "강철술사" && this.attacker.move.type == "강철"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "강철정신" && this.attacker.move.type == "강철"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "노말스킨"){
                power = power * 1.2
                // 선택한 기술타입을 "노말"(으)로 변경
            }
            else if(this.attacker.ability == "스카이스킨"){
                power = power * 1.2
                // 선택한 기술타입을 "비행"(으)로 변경
            }
            else if(this.attacker.ability == "일렉트릭스킨"){
                power = power * 1.2
                // 선택한 기술타입을 "전기"(으)로 변경
            }
            else if(this.attacker.ability == "프리즈스킨"){
                power = power * 1.2
                // 선택한 기술타입을 "얼음"(으)로 변경
            }
            else if(this.attacker.ability == "페어리스킨"){
                power = power * 1.2
                // 선택한 기술타입을 "페어리"(으)로 변경
            }
            else if(
                this.attacker.ability == "단단한발톱"
                // 접촉기술
                && (
                    (this.attacker.move.damage_class == "physical" && !this.touch_but_no_physical_list.includes(this.translateMove(this.attacker.move.name)))
                    || this.touch_but_special_list.includes(this.translateMove(this.attacker.move.name))
                )
            ){
                power = power * 1.3
            }
            else if(this.attacker.ability == "독폭주" && this.attacker.cc == "독" && this.attacker.move.damage_class == "physical"){
                power = power * 1.5
            }
            else if(
                this.attacker.ability == "모래의힘"
                && this.attacker.weather == "모래바람"
                && (
                    this.attacker.move.type == "바위"
                    || this.attacker.move.type == "땅"
                    || this.attacker.move.type == "강철"
                )
            ){
                power = power * 1.3
            }
            else if(this.attacker.ability == "바위나르기" && this.attacker.move.type == "바위"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "수포" && this.attacker.move.type == "물"){
                power = power * 2
            }
            else if(this.attacker.ability == "애널라이즈"){
                power = power * 1.3
            }
            else if(this.attacker.ability == "열폭주" && this.attacker.cc == "화상" && this.attacker.move.damage_class == "special"){
                power = power * 1.5
                // 원래 상태이상 화상은 기술이 물리일떄 0.5 -> 열폭주는 특공이랑 상관 X로 처리하지 않음 (여러개기술 시 고려필요) (현재는 반영 X)
                // 화상(Mod 1) 0.5 값을 1로 변경
            }
            else if(this.attacker.ability == "예리함" && this.cut_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.5
            }
            else if(this.attacker.ability == "옹골찬턱" && this.bite_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.5
            }
            else if(this.attacker.ability == "용의턱" && this.attacker.move.type == "드래곤"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "우격다짐"){
                power = power * 1.3
                // 부가효과가 있는 기술이 부가 효과가 사라지고 위력 1.3 (조건 구현 X)
            }
            else if(this.attacker.ability == "잠복"){
                power = power * 2
                // 교체로 나온 상대에게 (조건 구현 X)
            }
            else if(this.attacker.ability == "타오르는불꽃" && this.attacker.move.type == "불꽃"){
                power = power * 1.5
            }
            else if(this.attacker.ability == "트랜지스터" && this.attacker.move.type == "전기"){
                power = power * 1.3
            }
            else if(this.attacker.ability == "펑크록" && this.sound_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.3
            }
            else if(this.attacker.ability == "메가런처" && this.wave_list.includes(this.translateMove(this.attacker.move.name))){
                power = power * 1.5
            }
            else if(this.attacker.ability == "부자유친"){
                power = power * 1.25
            }
            else if(this.attacker.ability == "총대장 (1마리 기절)"){
                power = power * 1.1
            }
            else if(this.attacker.ability == "총대장 (2마리 기절)"){
                power = power * 1.2
            }
            else if(this.attacker.ability == "총대장 (3마리 기절)"){
                power = power * 1.3
            }
            else if(this.attacker.ability == "총대장 (4마리 기절)"){
                power = power * 1.4
            }
            else if(this.attacker.ability == "총대장 (5마리 기절)"){
                power = power * 1.5
            }

            // # 상대 특성 보정
            // if(this.defender.ability == "두꺼운지방" && (this.attacker.move.type == "불꽃" || this.attacker.move.type == "얼음")){
            //     power = power * 0.5
            // }
            // else if(this.defender.ability == "내열" && this.attacker.move.type == "불꽃"){
            //     power = power * 0.5
            // }
            // else if(this.defender.ability == "수포" && this.attacker.move.type == "불꽃"){
            //     power = power * 0.5
            // }
            // else if(this.defender.ability == "건조피부" && this.attacker.move.type == "불꽃"){
            //     power = power * 1.25
            // }
            // else if(this.defender.ability == "멀티스케일"){
            //     power = power * 0.5
            //     // 방어측 체력이 100% (조건 구현 X)
            // }
            // else if(this.defender.ability == "스펙터가드"){
            //     power = power * 0.5
            //     // 방어측 체력이 100% (조건 구현 X)
            // }
            // else if(this.defender.ability == "테라셸"){
            //     power = power * 0.5
            //     // 방어측 체력이 100% (조건 구현 X)
            // }
            // else if(this.defender.ability == "복슬복슬"){
            //     // 접촉기술
            //     if(
            //         (this.attacker.move.damage_class == "physical" && !this.touch_but_no_physical_list.includes(this.translateMove(this.attacker.move.name)))
            //         || this.touch_but_special_list.includes(this.translateMove(this.attacker.move.name))
            //     ){
            //         power = power * 0.5
            //     }
            //     else if(
            //         this.attacker.move.type == "불꽃"
            //     ){
            //         power = power * 2
            //     }
            // }
            // else if(this.defender.ability == "정화의소금" && this.attacker.move.type == "고스트"){
            //     power = power * 0.5
            // }
            // else if(this.defender.ability == "펑크록" && this.sound_list.includes(this.translateMove(this.attacker.move.name))){
            //     power = power * 0.5
            // }
            // else if(this.defender.ability == "퍼코트" && this.attacker.move.damage_class == "physical"){
            //     power = power * 0.5
            // }
            // else if(this.defender.ability == "얼음인분" && this.attacker.move.damage_class == "special"){
            //     power = power * 0.5
            // }

            // # 서포트 보정
            if(this.attacker.support.battery && this.attacker.move.damage_class == "special"){
                power = power * 1.3
            }
            if(this.attacker.support.fairy_aura && this.attacker.move.type == "페어리"){
                if(this.attacker.support.aura_break){
                    power = power * 0.75
                }else{
                    power = power * 1.33
                }
            }
            if(this.attacker.support.dark_aura && this.attacker.move.type == "악"){
                if(this.attacker.support.aura_break){
                    power = power * 0.75
                }else{
                    power = power * 1.33
                }
            }
            if(this.attacker.support.steel_spirit && this.attacker.move.type == "강철"){
                power = power * 1.5
            }
            if(this.attacker.support.power_spot){
                power = power * 1.3
            }
            if(this.attacker.support.friend_guard){
                power = power * 0.7
            }

            // 배수를 위력 공식 내에 추가
            power = power * this.attacker.multiple

            return power
        },

        // 공격력 (공격 실수값 X 각종 보정)
        caculated_attack(){
            let attack = 1

            // # 공격 실수값
            attack = this.attack_modifier

            // # 랭크보정
            attack = attack * this.rankup_value

            // # 특성보정
            if(this.attacker.ability == "근성" && this.attacker.cc != "" && this.attacker.move.damage_class == "physical"){
                attack = attack * 1.5
            }
            else if(this.attacker.ability == "의욕" && this.attacker.move.damage_class == "physical"){
                attack = attack * 1.5
            }
            else if(this.attacker.ability == "슬로스타트" && this.attacker.move.damage_class == "physical"){
                attack = attack * 0.5
            }
            else if(this.attacker.ability == "플러스" && this.attacker.move.damage_class == "special"){
                attack = attack * 1.5
            }
            else if(this.attacker.ability == "마이너스" && this.attacker.move.damage_class == "special"){
                attack = attack * 1.5
            }
            else if(this.attacker.ability == "선파워" && this.attacker.weather == "쾌청" && this.attacker.move.damage_class == "special"){
                attack = attack * 1.5
            }
            else if(this.attacker.ability == "무기력"){
                attack = attack * 0.5
            }
            else if(this.attacker.ability == "무아지경" && this.attacker.move.damage_class == "physical"){
                attack = attack * 1.5
            }
            // * 서포트인데 중간에 변경됨
            // else if(this.attacker.support.flower_gift && this.attacker.move.damage_class == "physical"){
            //     attack = attack * 1.5
            // }
            else if(this.attacker.ability == "고대활성" && (this.attacker.equipment == "부스트에너지" || this.attacker.weather == "쾌청")){
                attack = attack * 1.3
            }
            else if(this.attacker.ability == "쿼크차지" && (this.attacker.equipment == "부스트에너지" || this.attacker.field == "일렉트릭필드")){
                attack = attack * 1.3
            }
            else if(this.attacker.ability == "진홍빛고동" && this.attacker.move.damage_class == "physical"){
                attack = attack * 1.3
                // 날씨를 "쾌청"으로 바꿈
            }
            else if(this.attacker.ability == "하드론엔진" && this.attacker.move.damage_class == "special"){
                attack = attack * 1.3
                // 필드를 "일렉트릭필드"으로 바꿈
            }

            // # 상대특성보정
            if(this.defender.ability == "재앙의목간" && this.attacker.move.damage_class == "physical"){
                attack = attack * 0.75
            }
            else if(this.defender.ability == "재앙의그릇" && this.attacker.move.damage_class == "special"){
                attack = attack * 0.75
            }

            // # 도구보정
            if(this.attacker.equipment == "구애머리띠" && this.attacker.move.damage_class == "physical"){
                attack = attack * 1.5
            }
            else if(this.attacker.equipment == "구애안경" && this.attacker.move.damage_class == "special"){
                attack = attack * 1.5
            }
            else if(this.attacker.equipment == "심해의이빨" && this.translatePokemon(this.attacker.name) == "진주몽" && this.attacker.move.damage_class == "special"){
                attack = attack * 1.5
            }
            else if(this.attacker.equipment == "전기구슬" && this.translatePokemon(this.attacker.name) == "피카츄"){
                attack = attack * 2
            }
            else if(this.attacker.equipment == "굵은뼈" && (this.translatePokemon(this.attacker.name) == "탕구리" || this.translatePokemon(this.attacker.name) == "텅구리") && this.attacker.move.damage_class == "physical"){
                attack = attack * 2
            }
            else if(this.attacker.equipment == "부스트에너지"){
                attack = attack * 1
            }

            // # 서포트 보정
            if(this.attacker.support.flower_gift && this.attacker.weather == "쾌청" && this.attacker.move.damage_class == "physical"){
                attack = attack * 1.5
            }
            if(this.attacker.support.disaster_plate && this.attacker.move.damage_class == "physical"){
                attack = attack * 0.75
            }
            if(this.attacker.support.disaster_dish && this.attacker.move.damage_class == "special"){
                attack = attack * 0.75
            }

            // console.log("attack", attack)

            return attack
        },

        // 결정력
        potential(){
            return Math.floor(
                this.caculated_power
                * this.caculated_attack
                * this.mod0
                * this.mod1_for_potential // mod1에서 리플렉터, 빛의장막, 오로라베일은 제외
                // * this.same_type_value
                * this.same_type_modifier
                * this.mod2
                // * this.attacker.multiple
                * this.gebso
            )
        },

        // 자속 보정값 (공격자 포켓몬 타입과 기술 타입 일치 여부)
        same_type_modifier(){
            let same_type_value = 1

            // 포켓몬타입 == 기술타입
            if(this.attacker.types.includes(this.attacker.move.type)){
                if(this.attacker.ability == "적응력"){
                    if(this.attacker.stellar){
                        same_type_value = same_type_value * 2
                    }
                    else if(this.attacker.terastal){
                        same_type_value = same_type_value * 2.25
                    }
                    else{
                        same_type_value = same_type_value * 2
                    }
                }
                else{
                    if(this.attacker.stellar){
                        same_type_value = same_type_value * 2
                    }
                    else if(this.attacker.terastal){
                        same_type_value = same_type_value * 2
                    }
                    else{
                        same_type_value = same_type_value * 1.5
                    }
                }
            }

            // 포켓몬타입 != 기술타입
            else{
                // * 적응력 여부 상관없이 배수 같아서 통합
                if(this.attacker.stellar){
                    same_type_value = same_type_value * 1.2
                }
                else if(this.attacker.terastal){
                    same_type_value = same_type_value * 1.5
                }
            }

            return same_type_value
        },

        // 랭크업에 따른 보정값
        rankup_value(){
            if(this.attacker.rankup == -6) return 0.25
            else if(this.attacker.rankup == -5) return 0.29
            else if(this.attacker.rankup == -4) return 0.33
            else if(this.attacker.rankup == -3) return 0.4
            else if(this.attacker.rankup == -2) return 0.5
            else if(this.attacker.rankup == -1) return 0.66
            else if(this.attacker.rankup == 0) return 1
            else if(this.attacker.rankup == 1) return 1.5
            else if(this.attacker.rankup == 2) return 2
            else if(this.attacker.rankup == 3) return 2.5
            else if(this.attacker.rankup == 4) return 3
            else if(this.attacker.rankup == 5) return 3.5
            else if(this.attacker.rankup == 6) return 4
        },

        // 자속 보정값 (공격자 포켓몬 타입과 기술 타입 일치 여부)
        same_type_value(){
            if(this.attacker.types.includes(this.attacker.move.type)){
                return 1.5
            }else{
                return 1
            }
        },

        // # Mod0
        mod0(){
            let mod0 = 1

            if(this.attacker.field == "미스트필드" && this.attacker.move.type == "드래곤"){
                mod0 = mod0 * 0.5
            }

            return mod0
        },

        // # Mod1_for_potential
        mod1_for_potential(){
            let mod1 = 1

            // # 화상
            if(this.attacker.cc == "화상" && this.attacker.move.damage_class == "physical"){
                if(this.attacker.ability == "근성"){
                    mod1 = mod1 * 1
                }
                else{
                    mod1 = mod1 * 0.5
                }
            }

            // # 날씨
            if(this.attacker.weather == "쾌청"){
                if(this.attacker.move.type == "불꽃"){
                    mod1 = mod1 * 1.5
                }
                else if(this.attacker.move.type == "물"){
                    mod1 = mod1 * 0.5
                }
            }
            else if(this.attacker.weather == "비바라기"){
                if(this.attacker.move.type == "물"){
                    mod1 = mod1 * 1.5
                }
                else if(this.attacker.move.type == "불꽃"){
                    mod1 = mod1 * 0.5
                }
            }

            // # 필드
            if(this.attacker.field == "그래스필드"){
                if(this.attacker.move.type == "풀"){
                    mod1 = mod1 * 1.3
                }
                if(
                    this.translateMove(this.attacker.move.name) == "땅고르기"
                    || this.translateMove(this.attacker.move.name) == "지진"
                    || this.translateMove(this.attacker.move.name) == "매그니튜드"
                ){
                    mod1 = mod1 * 0.5
                }
            }
            else if(this.attacker.field == "사이코필드" && this.attacker.move.type == "에스퍼"){
                mod1 = mod1 * 1.3
            }
            else if(this.attacker.field == "일렉트릭필드" && this.attacker.move.type == "전기"){
                mod1 = mod1 * 1.3
            }

            return mod1
        },

        // # Mod1
        mod1(){
            let mod1 = 1

            // # 화상
            if(this.attacker.cc == "화상" && this.attacker.move.damage_class == "physical"){
                if(this.attacker.ability == "근성"){
                    mod1 = mod1 * 1
                }
                else{
                    mod1 = mod1 * 0.5
                }
            }

            // # 리플렉터
            if(this.defender && this.defender.support && this.defender.support.reflect && !this.defender.support.aurora_veil && this.attacker.move.damage_class == "physical"){
                mod1 = mod1 * 0.5
            }

            // # 빛의장막
            if(this.defender && this.defender.support && this.defender.support.light_screen && !this.defender.support.aurora_veil && this.attacker.move.damage_class == "special"){
                mod1 = mod1 * 0.5
            }

            // # 오로라베일
            if(this.defender && this.defender.support && this.defender.support.aurora_veil && !this.defender.support.reflect && !this.defender.support.light_screen){
                mod1 = mod1 * 0.5
            }

            // # 날씨
            if(this.attacker.weather == "쾌청"){
                if(this.attacker.move.type == "불꽃"){
                    mod1 = mod1 * 1.5
                }
                else if(this.attacker.move.type == "물"){
                    mod1 = mod1 * 0.5
                }
            }
            else if(this.attacker.weather == "비바라기"){
                if(this.attacker.move.type == "물"){
                    mod1 = mod1 * 1.5
                }
                else if(this.attacker.move.type == "불꽃"){
                    mod1 = mod1 * 0.5
                }
            }

            // # 필드
            if(this.attacker.field == "그래스필드"){
                if(this.attacker.move.type == "풀"){
                    mod1 = mod1 * 1.3
                }
                if(
                    this.translateMove(this.attacker.move.name) == "땅고르기"
                    || this.translateMove(this.attacker.move.name) == "지진"
                    || this.translateMove(this.attacker.move.name) == "매그니튜드"
                ){
                    mod1 = mod1 * 0.5
                }
            }
            else if(this.attacker.field == "사이코필드" && this.attacker.move.type == "에스퍼"){
                mod1 = mod1 * 1.3
            }
            else if(this.attacker.field == "일렉트릭필드" && this.attacker.move.type == "전기"){
                mod1 = mod1 * 1.3
            }

            return mod1
        },

        // # 급소
        gebso(){
            let gebso = 1

            if(this.attacker.support.gebso){
                if(this.attacker.ability == "스나이퍼"){
                    gebso = gebso * 2.25
                }else{
                    gebso = gebso * 1.5
                }
            }

            return gebso
        },

        // # Mod2
        mod2(){
            let mod2 = 1

            // # 생명의구슬
            if(this.attacker.equipment == "생명의구슬"){
                mod2 = mod2 * 1.3
            }

            // # 메트로놈
            if(this.attacker.equipment == "메트로놈 (1회)"){
                mod2 = mod2 * 1.2
            }
            else if(this.attacker.equipment == "메트로놈 (2회)"){
                mod2 = mod2 * 1.4
            }
            else if(this.attacker.equipment == "메트로놈 (3회)"){
                mod2 = mod2 * 1.6
            }
            else if(this.attacker.equipment == "메트로놈 (4회)"){
                mod2 = mod2 * 1.8
            }
            else if(this.attacker.equipment == "메트로놈 (5회)"){
                mod2 = mod2 * 2
            }

            return mod2
        },

        // # Mod3
        mod3(){
            let mod3 = 1

            // # 특성보정
            if(this.attacker.ability == "색안경" && ((this.type_modifier1 * this.type_modifier2) <= 0.5)){
                mod3 = mod3 * 2
            }
            else if(this.attacker.ability == "브레인포스" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 1.2
            }

            // # 상대특성보정
            if(this.defender && this.defender.ability == "하드록" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 0.75
            }
            else if(this.defender && this.defender.ability == "필터" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 0.75
            }
            else if(this.defender && this.defender.ability == "프리즘아머" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 0.75
            }

            // # 도구보정
            if(this.attacker.equipment == "달인의띠" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 1.2
            }

            // # 상대도구보정
            if(this.defender && this.defender.equipment == "반감열매"){
                if((this.type_modifier1 * this.type_modifier2) >= 2){
                    if(this.defender && this.defender.ability == "숙성"){
                        mod3 = mod3 * 0.5
                    }else{
                        mod3 = mod3 * 0.25
                    }
                }
            }

            // # 서포트
            if(this.attacker.support.aura_break && (this.attacker.support.pairy_aura || this.attacker.support.dark_aura)){
                mod3 = mod3 * 0.75
                // (체크필요) 페어리오라와 다크오라의 활성값을 0.75로 바꾼다
            }

            return mod3
        },
    },

    mounted(){
        // 포켓몬 목록 불러오기
        this.loadPokemonList()

        // 기술 목록 불러오기
        this.loadMoveList()

        this.loading = false

        if(this.$store.state.attacker.name){
            this.attacker = this.$store.state.attacker
        }
    },

    methods: {
        validateInput(property, value, min, max, defaultValue = 0) {
            if (value === "") {
                this.attacker[property] = defaultValue
                return
            }
            
            let numValue = parseInt(value)
            if (isNaN(numValue)) {
                this.attacker[property] = defaultValue
                return
            }
            
            this.attacker[property] = Math.max(min, Math.min(max, numValue))
        },

        updateInput(property, value) {
            // 현재 값을 숫자로 변환
            let current = parseInt(this.attacker[property]) || 0
            
            // 속성별 최소/최대값 설정
            const limits = {
                effort: { min: 0, max: 252 },
                individual_value: { min: 0, max: 31 },
                rankup: { min: -6, max: 6 },
                level: { min: 1, max: 100 }
            }
            
            // 새로운 값 계산
            let newValue = current + value
            
            // 범위 제한 적용
            if (limits[property]) {
                newValue = Math.max(limits[property].min, Math.min(limits[property].max, newValue))
            }
            
            // 값 업데이트
            this.attacker[property] = newValue
        },

        handleEnter(type, e) {
            // e.target이 undefined인 경우를 대비한 안전한 처리
            const currentValue = e?.target?.value
            if (!currentValue) return

            if (currentValue) {
                if (type === 'pokemon') {
                    // 한글 이름으로 영어 이름 찾기
                    const pokemon = this.pokemon_list.find(p => p.kr === currentValue)
                    if (pokemon) {
                        this.attacker.name = pokemon.en // 영어 이름으로 설정
                        this.loadAttacker()
                        this.$refs.pokemonAutocomplete.blur()
                    } else {
                        // 매칭되는 포켓몬이 없을 경우 직접 입력값 사용
                        this.attacker.name = currentValue 
                        this.loadAttacker()
                        this.$refs.pokemonAutocomplete.blur()
                    }
                } else if (type === 'move') {
                    // move 관련 로직
                    const move = this.move_list.find(m => m.kr === currentValue)
                    if (move) {
                        this.attacker.move.name = move.en
                        this.loadMove()
                        this.$refs.moveAutocomplete.blur()
                    } else {
                        this.attacker.move.name = currentValue
                        this.loadMove()
                        this.$refs.moveAutocomplete.blur()
                    }
                } else if (type === 'ability') {
                    this.attacker.ability = currentValue
                    this.$refs.abilityAutocomplete.blur()
                } else if (type === 'equipment') {
                    this.attacker.equipment = currentValue
                    this.$refs.equipmentAutocomplete.blur()
                }
            }
        },

        toggleMobileTab3() {
            if (this.$store.state.mobile_tab === 'docs_left') {
                this.$store.commit('update_mobile_tab', 'attacker')
            } else {
                this.$store.commit('update_mobile_tab', 'docs_left')
            }
        },
        
        toggleMobileTab2() {
            if (this.$store.state.mobile_tab === 'speed_attacker') {
                this.$store.commit('update_mobile_tab', 'speed_defender')
            } else {
                this.$store.commit('update_mobile_tab', 'defender')
            }
        },

        toggleMobileTab() {
            if (this.$store.state.mobile_tab === 'speed_attacker') {
                this.$store.commit('update_mobile_tab', 'attacker')
            } else {
                this.$store.commit('update_mobile_tab', 'speed_attacker')
            }
        },

        // 포켓몬 번역
        translatePokemon(en) {
            const pokemon = this.pokemon_list.find(item => item.en === en)
            return pokemon ? pokemon.kr : en
        },
        
        // 기술 번역
        translateMove(en) {
            const move = this.move_list.find(item => item.en === en)
            return move ? move.kr : en
        },

        restrictDecimal() {
            if(!this.attacker.multiple){
                this.attacker.multiple = 1
                return
            }

            let newVal = this.attacker.multiple
            const x = newVal.split('.')
            
            if (x.length > 1) {
                const decimal = x[1].slice(0, 2)
                if (decimal === '00') {
                    newVal = x[0]
                } else {
                    newVal = `${x[0]}.${decimal}`
                }
            }
            
            // 입력값이 0.1보다 작은 경우 0.1로 설정
            if (parseFloat(newVal) < 0.1) {
                newVal = '0.1'
            }
            
            // 입력값이 10보다 큰 경우 10으로 설정  
            if (parseFloat(newVal) > 10) {
                newVal = '10'
            }

            this.attacker.multiple = newVal
            this.componentKey++
        },

        // 포켓몬 목록 불러오기
        loadPokemonList() {
            this.$http.post("/api/pokemon/select")
            .then((res) => {
                this.pokemon_list = res.data
            })
        },

        // 기술 목록 불러오기
        loadMoveList() {
            this.$http.post("/api/move/select")
            .then((res) => {
                this.move_list = res.data
            })
        },

        // 포켓몬(Attacker) 선택 불러오기
        loadAttacker: _.debounce(function(e) {
            this.loading = true
            console.log(this.attacker.name)

            // pokemon_list에서 해당 포켓몬 찾기
            const pokemon = this.pokemon_list.find(p => p.en === this.attacker.name)
            
            if (pokemon) {
                // 포켓몬 이미지
                this.attacker.sprite = pokemon.sprite

                // 포켓몬 타입
                this.attacker.types = []
                if (pokemon.type1) this.attacker.types.push(pokemon.type1)
                if (pokemon.type2) this.attacker.types.push(pokemon.type2)

                // 포켓몬 스탯
                this.attacker.stats.hp = pokemon.h
                this.attacker.stats.attack = pokemon.a
                this.attacker.stats.defense = pokemon.b
                this.attacker.stats.special_attack = pokemon.c
                this.attacker.stats.special_defense = pokemon.d
                this.attacker.stats.speed = pokemon.s

                // 공격력
                this.attacker.damage = this.attacker.move.damage_class == "physical"? 
                this.attacker.stats.attack : this.attacker.stats.special_attack
            }

            this.loading = false

/*            this.$http.get("https://pokeapi.co/api/v2/pokemon/"+this.attacker.name)
            .then(async(res) => {
                // // 포켓몬 이미지
                // this.attacker.sprite = res.data.sprites.front_default

                // // 포켓몬 타입
                // this.attacker.types = []
                // await res.data.types.forEach(type => {
                //     this.$http.get(type.type.url)
                //     .then(res => {
                //         this.attacker.types.push(res.data.names[1].name)
                //     })
                // })

                // // 포켓몬 스탯
                // this.attacker.stats.hp = res.data.stats[0].base_stat
                // this.attacker.stats.attack = res.data.stats[1].base_stat
                // this.attacker.stats.defense = res.data.stats[2].base_stat
                // this.attacker.stats.special_attack = res.data.stats[3].base_stat
                // this.attacker.stats.special_defense = res.data.stats[4].base_stat
                // this.attacker.stats.speed = res.data.stats[5].base_stat

                // 공격력
                this.attacker.damage = this.attacker.move.damage_class == "physical"? this.attacker.stats.attack : this.attacker.stats.special_attack

                this.loading = false
            })*/
        }, 100, { leading: true, trailing: false }),

        // 기술 선택 불러오기
        loadMove(){
            if(this.attacker.move.name.includes("custom")){
                const move = this.move_list.find(item => item.en == this.attacker.move.name)
                if(move){
                    this.attacker.move.power = move.power
                    this.attacker.move.damage_class = move.damage_class
                    this.attacker.move.type = move.type

                    this.$emit("damage_class_updated", this.attacker.move.damage_class)
                }
            }
            else{
                this.$http.get("https://pokeapi.co/api/v2/move/"+this.attacker.move.name)
                .then((res) => {
                    // 기술 > 위력
                    this.attacker.move.power = res.data.power

                    // 기술 > 데미지 유형
                    this.attacker.move.damage_class = res.data.damage_class.name

                    // 공격력
                    this.attacker.damage = res.data.damage_class.name == "physical"? this.attacker.stats.attack : this.attacker.stats.special_attack

                    this.$emit("damage_class_updated", this.attacker.move.damage_class)

                    // 기술 > 타입
                    this.attacker.move.type = ""
                    this.$http.get(res.data.type.url)
                    .then(res => {
                        this.attacker.move.type = res.data.names[1].name
                    })
                })
            }
        },

        // Attacker > 포켓몬 타입 2개 이상 선택시 맨 앞 삭제
        attacker_types_updated(){
            if(this.attacker.types.length > 2){
                this.attacker.types.shift()
            }
        },

        // Attacker > 포켓몬 타입 삭제
        removeType(index) {
            this.attacker.types.splice(index, 1)
        },
    }
}
</script>

<style scoped>
::v-deep .center_label legend {
    text-align: center !important;
    width:40px;
}

::v-deep .center_label .v-text-field .v-label {
    left: 50% !important;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    color: #888888 !important;
    font-size: 9px !important;
    line-height:20px !important;
}

/* v-text-field, v-autocomplete > 입력값 글자 */
::v-deep .center_label .v-text-field input {
    color: #626262 !important;
    font-size:14px !important;
}

::v-deep .center_label .v-text-field .v-label--active {
    transform: translateY(-18px) translateX(-50%);
    padding:0 4px;
    background:white;
}
</style>