<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">진화과정 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        style="width:300px;"
                        class="shrink"
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>

                    <v-spacer></v-spacer>

                    <!-- 페이지 -->
                    <v-text-field
                        style="width:80px;"
                        class="shrink no-spinners"
                        outlined
                        dense
                        label="페이지"
                        hide-details
                        type="number"
                        v-model="page"
                        @change="options.page = parseInt(page)"
                    ></v-text-field>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected" hide-details>
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="paginatedItems"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td>{{item.en}} ({{item.kr}})</td>
                            <td>
                                <span v-if="item.evolution_chain_list">
                                    {{item.evolution_chain_list.split('_').map(pokemon => getPokemonKr(pokemon)).join(' → ')}}
                                </span>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:footer>
                        <v-pagination
                            style="border-top:1px solid #eee;"
                            class="pt-3 pb-2"
                            v-model="options.page"
                            :length="pageCount"
                            :total-visible="12"
                        ></v-pagination>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="pa-2 mt-2 d-flex justify-end" outlined>
                <v-btn
                    disabled
                    large
                    class="px-6 mr-4"
                >
                    선택항목: {{selectedItem.en}}({{selectedItem.kr}})
                </v-btn>
                <v-btn
                    large dark color="orange"
                    class="px-6"
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 수정하기 -->
        <v-dialog
            v-model="dialog.update"
            width="auto"
        >
            <v-sheet
                class="pa-2"
            >
                <div
                    class="d-flex"
                >
                    <div>
                        <table
                            class="updatetable"
                        >
                            <tr>
                                <th
                                    colspan="2"
                                    style="height:30px !important; padding:0; width:400px; text-align:center; background:#434343; color:white; font-size:12px;"
                                >
                                    기존
                                </th>
                            </tr>
                            <tr>
                                <th style="width:100px; height:45px !important; text-align:center;">모습</th>
                                <th style="width:300px; height:45px !important; text-align:center;">정보</th>
                            </tr>
                            <tr
                            v-for="(item, index) in selected_before_list"
                            :key="index"
                        >
                            <td style="width:100px;">
                                <v-icon
                                    v-if="!item.sprite"
                                    class="d-flex mx-auto"
                                    style="cursor:pointer;"
                                    @click="select_pokemon_open(index)"
                                >
                                    mdi-magnify
                                </v-icon>

                                <v-img
                                    v-else
                                    class="d-flex mx-auto"
                                    style="cursor:pointer;"
                                    :src="item.sprite"
                                    width="50"
                                    height="50"
                                    @click="select_pokemon_open(index)"
                                ></v-img>
                            </td>
                            <td style="width:300px;">
                                <p
                                    class="text-center mb-2"
                                >
                                    {{getPokemonKr(item.pokemon_en)}}
                                </p>
                                <p
                                    class="text-center"
                                    style="white-space: pre-line"
                                    v-html="replaceTextToImg(item.content)"
                                ></p>
                            </td>
                        </tr>
                        </table>
                    </div>

                    <table
                        class="updatetable ml-2"
                    >
                        <tr>
                            <th
                                colspan="2"
                                style="height:30px !important; padding:0; width:400px; text-align:center; background:#434343; color:white; font-size:12px;"
                            >
                                변경
                            </th>
                        </tr>
                        <tr>
                            <th style="width:100px; height:45px !important; text-align:center;">모습</th>
                            <th style="width:300px; height:45px !important; text-align:center;">정보</th>
                        </tr>
                        <tr
                            v-for="(item, index) in selected_after_list"
                            :key="index"
                        >
                            <td style="width:100px;">
                                <v-icon
                                    v-if="!item.sprite"
                                    class="d-flex mx-auto"
                                    style="cursor:pointer;"
                                    @click="select_pokemon_open(index)"
                                >
                                    mdi-magnify
                                </v-icon>

                                <v-img
                                    v-else
                                    class="d-flex mx-auto"
                                    style="cursor:pointer;"
                                    :src="item.sprite"
                                    width="50"
                                    height="50"
                                    @click="select_pokemon_open(index)"
                                ></v-img>
                            </td>
                            <td style="width:300px;">
                                <p
                                    class="text-center mb-2"
                                >
                                    {{getPokemonKr(item.pokemon_en)}}
                                </p>
                                <v-textarea
                                    rows="1"
                                    auto-grow
                                    outlined dense hide-details
                                    style="min-width:300px;"
                                    class="custom-textarea"
                                    v-model="item.content"
                                ></v-textarea>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="height:30px !important; padding:4px; width:400px; text-align:center; font-size:12px;">
                                <v-btn
                                    depressed
                                    color="grey lighten-1"
                                    dark
                                    x-small
                                    height="24"
                                    width="36"
                                    min-width="0"
                                    style="font-size:20px; font-weight:400 !important;"
                                    class="mr-1"
                                    @click="delete_column()"
                                >
                                    －
                                </v-btn>
                                <v-btn
                                    depressed
                                    color="#E9C46A"
                                    dark
                                    x-small
                                    height="24"
                                    width="36"
                                    min-width="0"
                                    style="font-size:20px; font-weight:400 !important;"
                                    @click="add_column()"
                                >
                                    ＋
                                </v-btn>
                            </td>
                        </tr>
                    </table>
                </div>

                <div
                    class="mt-2 pa-2 d-flex justify-center"
                >
                    <v-btn
                        large
                        dark
                        color="blue"
                        depressed
                        class="px-6 mr-2"
                        @click="updateSubmit(selectedItem.en)"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>
                        수정하기
                    </v-btn>
                    <v-btn
                        large
                        dark
                        color="grey"
                        depressed
                        class="px-6"
                        @click="dialog.update=false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        취소하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 수정하기 -->
        <v-dialog
            v-model="dialog.select_pokemon"
            width="auto"
        >
            <v-sheet
                class="pa-4 pt-4 rounded-10"
            >
                <div
                    class="d-flex align-center justify-space-between"
                >
                    <p
                        class="grey--text text--darken-2 mb-3"
                        style="font-weight:500; font-size:14px;"
                    >
                        포켓몬 선택
                    </p>

                    <v-icon
                        class="mb-3"
                        color="grey"
                        @click="dialog.select_pokemon = false"
                    >
                        mdi-close
                    </v-icon>
                </div>

                <!-- # 포켓몬 이름 -->
                <v-autocomplete
                    ref="pokemonAutocomplete"
                    class="rounded-lg shrink"
                    style="width:240px;"
                    placeholder="포켓몬 선택"
                    persistent-placeholder
                    dense
                    hide-details
                    item-value="en"
                    item-text="kr"
                    :items="pokemon_list"
                    v-model="pokemon"
                    outlined
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                    }"
                    @change="select_pokemon()"
                    @keydown.enter.native="handleEnter"
                ></v-autocomplete>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: '', width: '45px' },
            {text: '포켓몬 이름', value: 'en', width: '20%' },
            {text: '진화과정', value: 'evolution_chain_list', width: '80%' }
        ],

        // 검색 유형
        select_list: {
            search_type: [
                "영어 포켓몬 이름",
                "한글 포켓몬 이름"
            ]
        },

        // 검색 키워드
        search_type: "영어 포켓몬 이름",
        keyword: "",

        allItems: [], // API에서 가져온 모든 아이템
		options: {
			page: 1,
			itemsPerPage: 10
		},
		totalItems: 0,
        page: 0,

        // 수정
        selected: false,
        selectedItem: {},
        selected_before_list: [],
        selected_after_list: [
            {
                sprite: "",
                pokemon_en: "",
                content: ""
            }
        ],

        pokemon: "",

        dialog: {
            update:false,
            select_pokemon: false
        },

        pokemon_list: [],

        textList: [
            // 추가
            "#쪽빛구슬",
            "#주홍구슬",
            "#운석",
            "#대장의징표",
            "#지가르데큐브",
            "#테라스탈",
            "#울트라네크로Z",
            
            "#수컷",
            "#암컷",
            "#기술",
            "#레벨업",
            "#절친하트",
            "#아름다움",
            "#친밀도",
            "#지도",
            "#이끼바위",
            "#얼음바위",
            "#비",
            "#낮",
            "#밤",
            "#황혼",
            "#통신교환",
            "#뒤집기",
            "#성격치",
            "#소지",
            "#다이맥스",
            "#그라시데아꽃",
            "#비추는거울",
            "#유전자쐐기",
            "#굴레의항아리",
            "#네크로플러스루나",
            "#네크로플러스솔",
            "#다홍꿀",
            "#보라꿀",
            "#연분홍꿀",
            "#진노랑꿀",
            "#녹슨검",
            "#녹슨방패",
            "#로토무카탈로그",
            "#유대의고삐",
            "#큰금강옥",
            "#큰백옥",
            "#큰금백옥",
            "#주춧돌의가면",
            "#우물의가면",
            "#화덕의가면",
            "#이상해꽃나이트",
            "#리자몽나이트X",
            "#리자몽나이트Y",
            "#거북왕나이트",
            "#후디나이트",
            "#팬텀나이트",
            "#캥카나이트",
            "#쁘사이저나이트",
            "#갸라도스나이트",
            "#프테라나이트",
            "#뮤츠나이트X",
            "#뮤츠나이트Y",
            "#전룡나이트",
            "#핫삼나이트",
            "#헤라크로스나이트",
            "#헬가나이트",
            "#마기라스나이트",
            "#번치코나이트",
            "#가디안나이트",
            "#입치트나이트",
            "#보스로라나이트",
            "#요가램나이트",
            "#썬더볼트나이트",
            "#다크펫나이트",
            "#앱솔나이트",
            "#한카리아스나이트",
            "#루카리오나이트",
            "#눈설왕나이트",
            "#독침붕나이트",
            "#피죤투나이트",
            "#야도란나이트",
            "#강철톤나이트",
            "#나무킹나이트",
            "#대짱이나이트",
            "#깜까미나이트",
            "#샤크니아나이트",
            "#폭타나이트",
            "#파비코리나이트",
            "#얼음귀신나이트",
            "#보만다나이트",
            "#메타그로스나이트",
            "#라티아스나이트",
            "#라티오스나이트",
            "#이어롭나이트",
            "#엘레이드나이트",
            "#다부니 나이트",
            "#디안시나이트",
            "#주홍구슬",
            "#쪽빛구슬",
            "#불꽃의돌",
            "#물의돌",
            "#천둥의돌",
            "#리프의돌",
            "#달의돌",
            "#태양의돌",
            "#빛의돌",
            "#어둠의돌",
            "#각성의돌",
            "#동글동글돌",
            "#얼음의돌",
            "#금속코트",
            "#업그레이드",
            "#왕의징표석",
            "#용의비늘",
            "#심해의비늘",
            "#심해의이빨",
            "#괴상한패치",
            "#마그마부스터",
            "#에레키부스터",
            "#영계의천",
            "#프로텍터",
            "#예리한손톱",
            "#예리한이빨",
            "#고운비늘",
            "#휘핑팝",
            "#향기주머니",
            "#깨진포트",
            "#이빠진포트",
            "#달콤한사과",
            "#새콤한사과",
            "#딸기사탕공예",
            "#가라두구팔찌",
            "#가라두구머리장식",
            "#연결의끈",
            "#검은휘석",
            "#피트블록",
            "#악의족자",
            "#물의족자",
            "#저주받은갑옷",
            "#축복받은갑옷",
            "#꿀맛사과",
            "#범작찻잔",
            "#걸작찻잔",
            "#복합금속",
        ]
    }),

    computed: {
		pageCount() {
			return Math.ceil(this.totalItems / this.options.itemsPerPage)
		},

		paginatedItems() {
			const start = (this.options.page - 1) * this.options.itemsPerPage
			const end = start + this.options.itemsPerPage
            this.page = this.options.page
			return this.allItems.slice(start, end)
		}
	},

    mounted(){
        // 포켓몬 목록 불러오기
        this.loadPokemonList()

        // 목록 불러오기
        this.search()

        // 단축키 설정
        window.addEventListener('keydown', this.handleKeyDown)
    },

    methods: {
        // 포켓몬 목록 불러오기
        loadPokemonList() {
            this.$http.post("/api/pokemon/select")
            .then((res) => {
                this.pokemon_list = res.data
                console.log(this.pokemon_list)
            })
        },

        // 목록 불러오기
        search(){
            this.$http.post('/api/evolution_chain/select/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            })
            .then((res) => {
                this.allItems = res.data
                this.totalItems = this.allItems.length
            })
        },

        // 선택시 값 업데이트
        selectUpdate(item){
            this.selectedItem.id = item.id
            this.selectedItem.en = item.en
            this.selectedItem.kr = item.kr
            this.selectedItem.jp = item.jp
            this.selectedAfter.id = item.id
            this.selectedAfter.en = item.en
            this.selectedAfter.kr = item.kr
            this.selectedAfter.jp = item.jp
        },

        // 단축키로 수정하기 열기
        handleKeyDown(event) {
            if (event.ctrlKey && event.key === 'q') {
                this.updateOpen()
            }
        },

        // 진화과정 수정하기 열기
        updateOpen(){
            if(Object.keys(this.selectedItem).length){
                this.$http.post('/api/evolution_chain/select/specific', {
                    params: {
                        pokemon_en: this.selectedItem.en
                    }
                })
                .then((res) => {
                    console.log(res.data)
                    if(res.data.length){
                        this.selected_before_list = JSON.parse(JSON.stringify(res.data))
                        this.selected_after_list = res.data
                    }
                    this.dialog.update = true
                })
            }else{
                alert("선택한 포켓몬 이름이 없습니다.")
            }
        },

        // 영어 이름으로 한글 이름 찾기
        getPokemonKr(en) {
            const pokemon = this.pokemon_list.find(p => p.en === en)
            return pokemon ? pokemon.kr : ''
        },

        add_column(){
            this.selected_after_list.push({
                sprite: "",
                pokemon_en: "",
                content: ""
            })
        },

        delete_column(){
            this.selected_after_list.pop()
        },

        select_pokemon_open(index){
            this.dialog.select_pokemon = true
            this.selected_index = index
        },

        // 포켓몬 선택
        select_pokemon: _.debounce(function(e) {
            // this.$http.get("https://pokeapi.co/api/v2/pokemon/"+this.pokemon)
            // .then(async(res) => {
            //     // 포켓몬 이미지
            //     this.selected_after_list[this.selected_index].sprite = res.data.sprites.front_default

            //     // 포켓몬 영어 이름
            //     this.selected_after_list[this.selected_index].pokemon_en = this.pokemon

            //     // 선택 인덱스 초기화
            //     this.selected_index = null
            //     this.pokemon = ""

            //     // 포켓몬 선택 창 닫기
            //     this.dialog.select_pokemon = false
            // })

            this.$http.post("/api/pokemon/select/specific", {
                params: {
                    en: this.pokemon
                }
            })
            .then(async(res) => {
                // 포켓몬 이미지
                this.selected_after_list[this.selected_index].sprite = res.data[0].sprite

                // 포켓몬 영어 이름
                this.selected_after_list[this.selected_index].pokemon_en = this.pokemon

                // 선택 인덱스 초기화
                this.selected_index = null
                this.pokemon = ""

                // 포켓몬 선택 창 닫기
                this.dialog.select_pokemon = false
            })
        }, 100, { leading: true, trailing: false }),


        handleEnter(type, e) {
            // e.target이 undefined인 경우를 대비한 안전한 처리
            const currentValue = e?.target?.value
            if (!currentValue) return

            if (currentValue) {
                if (type === 'pokemon') {
                    // 한글 이름으로 영어 이름 찾기
                    const pokemon = this.pokemon_list.find(p => p.kr === currentValue)
                    if (pokemon) {
                        this.pokemon = pokemon.en // 영어 이름으로 설정
                        this.select_pokemon()
                        this.$refs.pokemonAutocomplete.blur()
                    } else {
                        // 매칭되는 포켓몬이 없을 경우 직접 입력값 사용
                        this.pokemon = currentValue 
                        this.select_pokemon()
                        this.$refs.pokemonAutocomplete.blur()
                    }
                }
            }
        },

        // 진화과정 수정하기 제출
        async updateSubmit() {
            try {
                // 기본 포켓몬에 대한 진화 체인 삭제 및 추가
                await this.applyEvolutionChain(this.selectedItem.en)


                let same_evolution_list = this.selected_after_list.map(item => item.pokemon_en)
                
                // same_evolution_list가 있다면 추가 포켓몬들에 대해서도 동일하게 적용
                if (same_evolution_list) {
                    for (const pokemon of same_evolution_list) {
                        if (pokemon) {
                            await this.applyEvolutionChain(pokemon)
                        }
                    }
                }

                // 모든 처리가 완료되면 상태 초기화
                this.dialog.update = false
                this.selected = false
                this.selectedItem = {}
                this.selected_after_list = [{
                    sprite: "",
                    pokemon_en: "",
                    content: ""
                }]
                same_evolution_list = ""
                
                // 목록 새로고침
                this.search()

            } catch (error) {
                console.error('진화과정 수정 중 오류 발생:', error)
                alert('진화과정 수정 중 오류가 발생했습니다.')
            }
        },

        // 진화 체인 적용을 위한 새로운 메소드
        async applyEvolutionChain(pokemonEn) {
            // 기존 진화 체인 삭제
            await this.$http.post('/api/evolution_chain/delete', {
                params: {
                    pokemon_en: pokemonEn
                }
            })

            // selected_after_list의 각 항목에 대해 순차적으로 insert
            for (const [index, item] of this.selected_after_list.entries()) {
                await this.$http.post('/api/evolution_chain/insert', {
                    params: {
                        origin_pokemon_en: pokemonEn,
                        slot: index,
                        pokemon_en: item.pokemon_en,
                        sprite: item.sprite,
                        content: item.content
                    }
                })
            }
        },

        // 글자를 이미지로 변경
        // replaceTextToImg(text){
        //     if(!text){
        //         return text
        //     }

        //     const baseUrl = process.env.NODE_ENV === 'production' 
        //     ? 'https://smartnuo.com'
        //     : 'http://localhost:8081'
            
        //     return text.replaceAll(text, `<img width="20" height="20" style="vertical-align:sub;" src="${baseUrl}/upload/evolution/${text}.png" title="${text}"></img>`)
        // },

        replaceTextToImg(text) {
            if (!text) {
                return text
            }

            const baseUrl = process.env.NODE_ENV === 'production' 
                ? 'https://smartnuo.com'
                : 'http://localhost:8081'

            let result = text
            this.textList.forEach(item => {
                if (result.includes(item)) {
                    // #을 제거한 문자열 생성
                    const itemWithoutHash = item.replace('#', '')
                    
                    // 이미지 태그 수정
                    const imgTag = `<img 
                        height="14" 
                        style="
                            vertical-align: middle;
                            display: inline-block;
                            position: relative;
                            top: -2px;
                            margin: 0 2px;
                        " 
                        src="${baseUrl}/upload/evolution/${itemWithoutHash}.png" 
                        title="${itemWithoutHash}"></img>`
                    result = result.replaceAll(item, imgTag)
                }
            })
            
            return result
        }
    }
}
</script>

<style scoped>
.updatetable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.updatetable tr td{
    height:50px;
    padding:10px;
    border:1px solid #dedede;
}

.updatetable tr th{
    background:#f2f2f2;
    text-align: left;
    padding:10px;
    border:1px solid #dedede;
}
</style>
<style scoped>
/* v-text-field, v-autocomplete > 입력값 글자 가운데 정렬 */
::v-deep .v-text-field .v-input__slot input {
    text-align: left;
}
</style>

<style scoped>
/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#99d1e5 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

/* v-text-field, v-select, v-autocomplete > 전체 높이 지정 */
::v-deep .v-text-field .v-input__slot { 
    height: 40px;
}

/* v-text-field, v-select, v-autocomplete > ▼ 아이콘 (v-text-field 한정 append-icon) */
::v-deep .v-text-field .v-input__icon--append .v-icon {
    padding-bottom: 11px;
}

</style>
<style scoped>
/* v-textarea의 높이 제한을 해제 */
::v-deep .custom-textarea .v-input__slot {
    height: auto !important;
    min-height: 50px;
}
</style>