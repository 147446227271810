<template>
    <v-sheet color="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">포켓몬 값 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        style="width:300px;"
                        class="shrink"
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>

                    <v-spacer></v-spacer>

                    <!-- 페이지 -->
                    <v-text-field
                        style="width:80px;"
                        class="shrink no-spinners"
                        outlined
                        dense
                        label="페이지"
                        hide-details
                        type="number"
                        v-model="page"
                        @change="options.page = parseInt(page)"
                    ></v-text-field>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected" hide-details>
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="paginatedItems"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td class="px-2 py-2">
                                <div class="d-flex">
                                    <v-img
                                        class="d-block"
                                        width="40"
                                        height="40"
                                        :src="item.sprite"
                                    ></v-img>
                                    <v-img
                                        class="d-block"
                                        width="40"
                                        height="40"
                                        :src="item.sprite_shiny"
                                    ></v-img>
                                </div>
                            </td>
                            <td>{{item.en}} ({{item.kr}})</td>
                            <td>{{item.h}}</td>
                            <td>{{item.a}}</td>
                            <td>{{item.b}}</td>
                            <td>{{item.c}}</td>
                            <td>{{item.d}}</td>
                            <td>{{item.s}}</td>
                            <td>{{item.type1}}</td>
                            <td>{{item.type2}}</td>
                        </tr>
                    </template>
                    <template v-slot:footer>
                        <v-pagination
                            style="border-top:1px solid #eee;"
                            class="pt-3 pb-2"
                            v-model="options.page"
                            :length="pageCount"
                            :total-visible="12"
                        ></v-pagination>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="pa-2 mt-2 d-flex justify-end" outlined>
                <v-btn
                    disabled
                    large
                    class="px-6 mr-4"
                >
                    선택항목: {{selectedItem.en}}({{selectedItem.kr}})
                </v-btn>
                <v-btn
                    large dark color="orange"
                    class="px-6"
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
                <v-btn
                    large dark color="blue"
                    class="px-6 ml-3"
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
                <v-btn
                    large dark color="blue"
                    class="px-6 ml-3"
                    @click="dialog.create = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn>
            </v-card>

            <!-- dialog -->
            <v-sheet>
                <!-- 수정하기 -->
                <v-dialog v-model="dialog.update" width="auto">
                    <v-sheet class="pa-2">
                        <v-sheet>
                            <table class="updatetable">
                                <tr>
                                    <th style="width:54px;"></th>
                                    <th style="width:200px;">영어 포켓몬 이름</th>
                                    <th style="width:200px;">한글 포켓몬 이름</th>
                                    <th style="width:200px;">일문 포켓몬 이름</th>
                                    <th style="width:180px;">초상화</th>
                                    <th style="width:180px;">초상화 (shiny)</th>
                                    <th style="width:80px;">H</th>
                                    <th style="width:80px;">A</th>
                                    <th style="width:80px;">B</th>
                                    <th style="width:80px;">C</th>
                                    <th style="width:80px;">D</th>
                                    <th style="width:80px;">S</th>
                                    <th style="width:120px;">타입1</th>
                                    <th style="width:120px;">타입2</th>
                                </tr>
                                <tr>
                                    <td style="width:54px;">기존</td>
                                    <td style="width:200px;">{{selectedItem.en}}</td>
                                    <td style="width:200px;">{{selectedItem.kr}}</td>
                                    <td style="width:200px;">{{selectedItem.jp}}</td>
                                    <td style="width:180px;" class="py-1">
                                        <v-img
                                            class="d-block mx-auto"
                                            width="72"
                                            height="72"
                                            :src="selectedItem.sprite"
                                        ></v-img>
                                    </td>
                                    <td style="width:180px;" class="py-1">
                                        <v-img
                                            class="d-block mx-auto"
                                            width="72"
                                            height="72"
                                            :src="selectedItem.sprite_shiny"
                                        ></v-img>
                                    </td>
                                    <td style="width:80px;">{{selectedItem.h}}</td>
                                    <td style="width:80px;">{{selectedItem.a}}</td>
                                    <td style="width:80px;">{{selectedItem.b}}</td>
                                    <td style="width:80px;">{{selectedItem.c}}</td>
                                    <td style="width:80px;">{{selectedItem.d}}</td>
                                    <td style="width:80px;">{{selectedItem.s}}</td>
                                    <td style="width:120px;">{{selectedItem.type1}}</td>
                                    <td style="width:120px;">{{selectedItem.type2}}</td>
                                </tr>
                                <tr v-if="selectedAfter">
                                    <td style="width:54px;">변경</td>
                                    <td style="width:200px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:200px;"
                                            v-model="selectedAfter.en"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:200px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:200px;"
                                            v-model="selectedAfter.kr"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:200px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:200px;"
                                            v-model="selectedAfter.jp"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:180px;">
                                        <v-file-input
                                            placeholder="이미지 업로드"
                                            outlined
                                            dense
                                            hide-details
                                            prepend-icon
                                            color="blue"
                                            v-model="uploadImage"
                                        ></v-file-input>
                                    </td>
                                    <td style="width:180px;">
                                        <v-file-input
                                            placeholder="이미지 업로드"
                                            outlined
                                            dense
                                            hide-details
                                            prepend-icon
                                            color="blue"
                                            v-model="uploadImage2"
                                        ></v-file-input>
                                    </td>
                                    <td style="width:80px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:80px;"
                                            v-model="selectedAfter.h"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:80px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:80px;"
                                            v-model="selectedAfter.a"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:80px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:80px;"
                                            v-model="selectedAfter.b"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:80px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:80px;"
                                            v-model="selectedAfter.c"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:80px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:80px;"
                                            v-model="selectedAfter.d"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:80px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:80px;"
                                            v-model="selectedAfter.s"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:120px;">
                                        <v-select
                                            outlined dense hide-details
                                            style="min-width:120px;"
                                            :items="type_list"
                                            v-model="selectedAfter.type1"
                                        ></v-select>
                                    </td>
                                    <td style="width:120px;">
                                        <v-select
                                            outlined dense hide-details
                                            style="min-width:120px;"
                                            :items="type_list"
                                            v-model="selectedAfter.type2"
                                        ></v-select>
                                    </td>
                                </tr>
                            </table>
                        </v-sheet>
                        <v-sheet class="mt-2 pa-2 d-flex justify-center">
                            <v-btn
                                large dark color="blue"
                                class="px-6 mr-4"
                                @click="updateSubmit()"
                            >
                                <v-icon small left>mdi-database-edit-outline</v-icon>
                                수정하기
                            </v-btn>
                            <v-btn
                                large dark color="grey"
                                class="px-6"
                                @click="dialog.update=false"
                            >
                                <v-icon small left>mdi-cancel</v-icon>
                                취소하기
                            </v-btn>
                        </v-sheet>
                    </v-sheet>
                </v-dialog>

                <!-- 추가하기 -->
                <v-dialog v-model="dialog.create" width="auto">
                    <v-sheet class="pa-2">
                        <v-sheet>
                            <table class="updatetable">
                                <tr>
                                    <th style="width:180px;">영어 포켓몬 이름</th>
                                    <th style="width:180px;">한글 포켓몬 이름</th>
                                    <th style="width:180px;">일문 포켓몬 이름</th>
                                    <th style="width:180px;">초상화</th>
                                    <th style="width:180px;">초상화(shiny)</th>
                                    <th style="width:80px;">H</th>
                                    <th style="width:80px;">A</th>
                                    <th style="width:80px;">B</th>
                                    <th style="width:80px;">C</th>
                                    <th style="width:80px;">D</th>
                                    <th style="width:80px;">S</th>
                                    <th style="width:120px;">타입1</th>
                                    <th style="width:120px;">타입2</th>
                                </tr>
                                <tr>
                                    <td style="width:180px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:180px;"
                                            v-model="create.en"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:180px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:180px;"
                                            v-model="create.kr"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:180px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:180px;"
                                            v-model="create.jp"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:180px;">
                                        <v-file-input
                                            placeholder="업로드"
                                            outlined
                                            dense
                                            hide-details
                                            prepend-icon
                                            color="blue"
                                            v-model="uploadImage"
                                        ></v-file-input>
                                    </td>
                                    <td style="width:180px;">
                                        <v-file-input
                                            placeholder="업로드"
                                            outlined
                                            dense
                                            hide-details
                                            prepend-icon
                                            color="blue"
                                            v-model="uploadImage2"
                                        ></v-file-input>
                                    </td>
                                    <td style="width:80px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:80px;"
                                            v-model="create.h"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:80px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:80px;"
                                            v-model="create.a"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:80px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:80px;"
                                            v-model="create.b"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:80px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:80px;"
                                            v-model="create.c"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:80px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:80px;"
                                            v-model="create.d"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:80px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:80px;"
                                            v-model="create.s"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:120px;">
                                        <v-select
                                            outlined dense hide-details
                                            style="min-width:120px;"
                                            :items="type_list"
                                            v-model="create.type1"
                                        ></v-select>
                                    </td>
                                    <td style="width:120px;">
                                        <v-select
                                            outlined dense hide-details
                                            style="min-width:120px;"
                                            :items="type_list"
                                            v-model="create.type2"
                                        ></v-select>
                                    </td>
                                </tr>
                            </table>
                        </v-sheet>
                        <v-sheet class="mt-2 pa-2 d-flex justify-center">
                            <v-btn
                                large dark color="blue"
                                class="px-6 mr-4"
                                @click="createSubmit()"
                            >
                                <v-icon small left>mdi-database-edit-outline</v-icon>
                                추가하기
                            </v-btn>
                            <v-btn
                                large dark color="grey"
                                class="px-6"
                                @click="dialog.create=false"
                            >
                                <v-icon small left>mdi-cancel</v-icon>
                                취소하기
                            </v-btn>
                        </v-sheet>
                    </v-sheet>
                </v-dialog>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
import common from '@/data/common.json'

export default {
    data: () => ({
        // 타입 목록
        type_list: common.type_list,

        headers: [
            {text: '', value: '', width: '45px' },
            {text: '', value: 'sprite', width: '120px' },
            {text: '포켓몬 이름', value: 'en', width: '30%' },
            {text: 'H', value: 'H', width: '7%' },
            {text: 'A', value: 'A', width: '7%' },
            {text: 'B', value: 'B', width: '7%' },
            {text: 'C', value: 'C', width: '7%' },
            {text: 'D', value: 'D', width: '7%' },
            {text: 'S', value: 'S', width: '7%' },
            {text: '타입 1', value: 'type_1', width: '10%' },
            {text: '타입 2', value: 'type_2', width: '10%' },
        ],

        select_list: {
            search_type: [
                "영어 포켓몬 이름",
                "한글 포켓몬 이름",
            ],
        },

        search_type: "영어 포켓몬 이름",
        keyword: "",

        allItems: [], // API에서 가져온 모든 아이템
		options: {
			page: 1,
			itemsPerPage: 10
		},
		totalItems: 0,
        page: 0,

        selected: false,
        selectedItem: {},
        selectedAfter: {},

        dialog: {
            update:false,
            create:false
        },

        create: {
            en: "",
            kr: "",
            jp: "",
            sprite: "",
            h: "",
            a: "",
            b: "",
            c: "",
            d: "",
            s: "",
            type1: "",
            type2: "",
        },

        uploadImage: null,
    }),

    computed: {
		pageCount() {
			return Math.ceil(this.totalItems / this.options.itemsPerPage)
		},
		paginatedItems() {
			const start = (this.options.page - 1) * this.options.itemsPerPage
			const end = start + this.options.itemsPerPage
            this.page = this.options.page
			return this.allItems.slice(start, end)
		}
	},

    mounted(){
        // 목록 불러오기
        this.search()

        window.addEventListener('keydown', this.handleKeyDown)
    },

    methods: {
        handleKeyDown(event) {
            if (event.ctrlKey && event.key === 'q') {
                this.updateOpen()
            }
        },

        // 목록 불러오기
        search(){
            this.$http.post('/api/pokemon/select/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            })
            .then((res) => {
                this.allItems = res.data
                this.totalItems = this.allItems.length
            })
        },

        // 목록 불러오기
        search2(){
            this.$http.post('/api/pokemon/select/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            })
            .then((res) => {
                this.allItems = res.data
                this.totalItems = this.allItems.length

                // 마지막 페이지 계산 및 이동
                const lastPage = Math.ceil(this.totalItems / this.options.itemsPerPage)
                this.options.page = lastPage
                this.page = lastPage
            })
        },

        // 선택시 값 업데이트
        selectUpdate(item){
            this.selectedItem.id = item.id
            this.selectedItem.en = item.en
            this.selectedItem.kr = item.kr
            this.selectedItem.jp = item.jp
            this.selectedItem.sprite = item.sprite
            this.selectedItem.sprite_shiny = item.sprite_shiny
            this.selectedItem.h = item.h
            this.selectedItem.a = item.a
            this.selectedItem.b = item.b
            this.selectedItem.c = item.c
            this.selectedItem.d = item.d
            this.selectedItem.s = item.s
            this.selectedItem.type1 = item.type1
            this.selectedItem.type2 = item.type2

            this.selectedAfter.id = item.id
            this.selectedAfter.en = item.en
            this.selectedAfter.kr = item.kr
            this.selectedAfter.jp = item.jp
            this.selectedAfter.sprite = item.sprite
            this.selectedAfter.sprite_shiny = item.sprite_shiny
            this.selectedAfter.h = item.h
            this.selectedAfter.a = item.a
            this.selectedAfter.b = item.b
            this.selectedAfter.c = item.c
            this.selectedAfter.d = item.d
            this.selectedAfter.s = item.s
            this.selectedAfter.type1 = item.type1
            this.selectedAfter.type2 = item.type2
        },

        // 포켓몬 수정 open
        updateOpen(){
            if(Object.keys(this.selectedItem).length){
                this.dialog.update = true
            }else{
                alert("선택된 포켓몬이 없습니다.")
            }
        },

        // 포켓몬 수정 Submit
        updateSubmit(){
            if(this.uploadImage && !this.uploadImage2){
                var file = this.uploadImage
                var formData = new FormData()
                formData.append("image", file)

                this.$http.post('/api/file/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "sprite"
                    }
                }).then((res) => {
                    this.selectedAfter.sprite = "https://smartnuo.com/upload/sprite/" + res.data
                    // this.selectedAfter.sprite = "/upload/sprite/" + res.data

                    this.$http.post('/api/pokemon/update/all', {
                        params: {
                            before: this.selectedItem,
                            after: this.selectedAfter
                        }
                    }).then((res) => {
                        // alert("해당 포켓몬 정보가 수정되었습니다.")
                        this.dialog.update = false
                        this.selected = false
                        this.selectedItem = {}
                        this.selectedAfter = {}
                        this.uploadImage = null
                        this.search()
                    })
                })
            }
            if(!this.uploadImage && this.uploadImage2){
                var file = this.uploadImage2
                var formData = new FormData()
                formData.append("image", file)

                this.$http.post('/api/file/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "sprite"
                    }
                }).then((res) => {
                    this.selectedAfter.sprite_shiny = "https://smartnuo.com/upload/sprite/" + res.data
                    // this.selectedAfter.sprite_shiny = "/upload/sprite/" + res.data

                    this.$http.post('/api/pokemon/update/all', {
                        params: {
                            before: this.selectedItem,
                            after: this.selectedAfter
                        }
                    }).then((res) => {
                        // alert("해당 포켓몬 정보가 수정되었습니다.")
                        this.dialog.update = false
                        this.selected = false
                        this.selectedItem = {}
                        this.selectedAfter = {}
                        this.uploadImage2 = null
                        this.search()
                    })
                })
            }
            if(this.uploadImage && this.uploadImage2){
                var file = this.uploadImage
                var formData = new FormData()
                formData.append("image", file)

                this.$http.post('/api/file/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "sprite"
                    }
                }).then((res) => {
                    var file = this.uploadImage2
                    var formData = new FormData()
                    formData.append("image", file)

                    this.$http.post('/api/file/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        params: {
                            type: "sprite"
                        }
                    }).then((res2) => {
                        this.selectedAfter.sprite = "https://smartnuo.com/upload/sprite/" + res.data
                        this.selectedAfter.sprite_shiny = "https://smartnuo.com/upload/sprite/" + res2.data

                        // this.selectedAfter.sprite = "/upload/sprite/" + res.data
                        // this.selectedAfter.sprite_shiny = "/upload/sprite/" + res2.data

                        this.$http.post('/api/pokemon/update/all', {
                            params: {
                                before: this.selectedItem,
                                after: this.selectedAfter
                            }
                        }).then((res) => {
                            // alert("해당 포켓몬 정보가 수정되었습니다.")
                            this.dialog.update = false
                            this.selected = false
                            this.selectedItem = {}
                            this.selectedAfter = {}
                            this.uploadImage = null
                            this.uploadImage2 = null
                            this.search()
                        })
                    })
                })
            }
            else{
                this.$http.post('/api/pokemon/update/all', {
                    params: {
                        before: this.selectedItem,
                        after: this.selectedAfter
                    }
                }).then((res) => {
                    // alert("해당 포켓몬 정보가 수정되었습니다.")
                    this.dialog.update = false
                    this.selected = false
                    this.selectedItem = {}
                    this.selectedAfter = {}
                    this.uploadImage = null
                    this.search()
                })
            }
        },

        // 포켓몬 추가 Submit
        createSubmit(){
            if(this.uploadImage){
                var file = this.uploadImage
                var formData = new FormData()
                formData.append("image", file)

                this.$http.post('/api/file/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "sprite"
                    }
                }).then((res) => {
                    if(this.uploadImage2){
                        var file = this.uploadImage2
                        var formData = new FormData()
                        formData.append("image", file)

                        this.$http.post('/api/file/upload', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            params: {
                                type: "sprite"
                            }
                        }).then((res2) => {
                            this.create.sprite = "https://smartnuo.com/upload/sprite/" + res.data
                            this.create.sprite_shiny = "https://smartnuo.com/upload/sprite/" + res2.data

                            // 테스트
                            // this.create.sprite = "/upload/sprite/" + res.data
                            // this.create.sprite_shiny = "/upload/sprite/" + res2.data

                            this.$http.post('/api/pokemon/insert/all', {
                                params: {
                                    create: this.create
                                }
                            }).then((res) => {
                                alert("포켓몬이 추가되었습니다.")
                                this.dialog.create = false
                                this.create = {}
                                this.uploadImage = null
                                this.uploadImage2 = null
                                this.search2()
                            })
                        })
                    }
                })
            }
        },

        // 포켓몬 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택된 포켓몬이 없습니다.")
            }else{
                if(confirm('정말 해당 포켓몬을 삭제하시겠습니까?')){
                    this.$http.post('/api/pokemon/delete', {
                        params: {
                            id: this.selectedItem.id,
                        }
                    }).then((res) => {
                        alert("해당 포켓몬이 삭제되었습니다.")
                        this.search()
                    })
                }
            }
        }
    }
}
</script>

<style scoped>
.updatetable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.updatetable tr td{
    height:50px;
    padding:10px;
    border:1px solid #dedede;
}

.updatetable tr th{
    background:#f2f2f2;
    text-align: left;
    padding:10px;
    border:1px solid #dedede;
}
</style>
<style scoped>
/* v-text-field, v-autocomplete > 입력값 글자 가운데 정렬 */
::v-deep .v-text-field .v-input__slot input {
    text-align: left;
}
</style>

<style scoped>
/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#99d1e5 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

/* v-text-field, v-select, v-autocomplete > 전체 높이 지정 */
::v-deep .v-text-field .v-input__slot { 
    height: 40px;
}

/* v-text-field, v-select, v-autocomplete > ▼ 아이콘 (v-text-field 한정 append-icon) */
::v-deep .v-text-field .v-input__icon--append .v-icon {
    padding-bottom: 11px;
}

</style>