<template>
    <div
        class="pa-12"
    >
        {{pokemon_list}}
        <v-btn
            @click="updateDB()"
            large
            depressed
            dark
            color="primary"
            class="px-12 rounded-10"
        >
            입력
        </v-btn>
    </div>
</template>
<script>
export default {
    data: () => ({
        pokemon_list: [],
    }),

    mounted(){
        this.fetchAllPokemonNames()
    },

    methods: {
        // async fetchAllPokemonNames() {
        //     // try {
        //     //     const response = await fetch('https://pokeapi.co/api/v2/pokemon?limit=2000')
        //     //     const data = await response.json()
        //     //     const pokemonNames = data.results.filter(pokemon => {
        //     //         const id = parseInt(pokemon.url.split('/').slice(-2, -1)[0])
        //     //         return id < 10000 && id !== 1025
        //     //     })
                
        //     //     this.pokemon_list = pokemonNames.map(p => p.name)
        //     //     console.log(this.pokemon_list)
        //     // } catch (error) {
        //     //     console.error('Error:', error)
        //     // }

        //     try {
        //         const response = await fetch('https://pokeapi.co/api/v2/pokemon?limit=2000')
        //         const data = await response.json()
        //         this.pokemon_list = data.results.map(p => p.name)
        //         console.log(this.pokemon_list)
        //     } catch (error) {
        //         console.error('Error:', error)
        //     }
        // },

        async fetchAllPokemonNames() {
            try {
                // 기본 포켓몬 리스트를 가져옴
                const response = await fetch('https://pokeapi.co/api/v2/pokemon?limit=2000')
                const data = await response.json()
                let pokemonList = data.results.map(p => p.name)
                
                // 포켓몬 폼 리스트를 가져옴
                const formResponse = await fetch('https://pokeapi.co/api/v2/pokemon-form?limit=2000')
                const formData = await formResponse.json()
                const formList = formData.results.map(f => f.name)
                
                // 두 리스트를 합침
                this.pokemon_list = [...new Set([...pokemonList, ...formList])]
                console.log(this.pokemon_list)
            } catch (error) {
                console.error('Error:', error)
            }
        },

        async updatePokemonInfo(pokemonName) {
            try {
                const res = await this.$http.get(`https://pokeapi.co/api/v2/pokemon/${pokemonName}`)
                
                // 기본 정보 추출
                const sprite = res.data.sprites.front_default
                const sprite_shiny = res.data.sprites.front_shiny
                const stats = {
                    h: res.data.stats[0].base_stat,
                    a: res.data.stats[1].base_stat, 
                    b: res.data.stats[2].base_stat,
                    c: res.data.stats[3].base_stat,
                    d: res.data.stats[4].base_stat,
                    s: res.data.stats[5].base_stat
                }
                
                // 타입 정보 추출 및 한글화
                const types = res.data.types
                const typePromises = types.map(type => 
                    this.$http.get(type.type.url)
                    .then(res => res.data.names[1].name)
                )
                const koreanTypes = await Promise.all(typePromises)
                const type1 = koreanTypes[0] || null
                const type2 = koreanTypes[1] || null

                // DB 업데이트
                await this.$http.post("/api/pokemon/update/insert", {
                    params: {
                        en: pokemonName,
                        sprite,
                        sprite_shiny,
                        ...stats,
                        type1,
                        type2
                    }
                })

                console.log(`${pokemonName} 업데이트 완료`)

            } catch (err) {
                console.error(`${pokemonName} 업데이트 실패:`, err)
            }
        },

        async updateDB() {
            for (const pokemonName of this.pokemon_list) {
                await this.updatePokemonInfo(pokemonName)
                // API 호출 제한을 위한 딜레이
                await new Promise(resolve => setTimeout(resolve, 10))
            }
            console.log('모든 포켓몬 정보 업데이트 완료')
        }
    }
}
</script>