<template>
    <v-sheet
        :class="!$vuetify.breakpoint.mobile? 'rounded-12':''"
        :style="!$vuetify.breakpoint.mobile?
            {
                width: '362px',
                minHeight: '780px',
                border: '1px solid #ddd',
                paddingBottom: '10px',
                boxShadow: '0 0 6px 0 #e1e1e1'
            }
            : 
            {
                height: '100%'
            }
        "
    >
        <!-- # 1줄 -->
        <div
            class="d-flex align-center docs_pokemon_autocomplete"
            :style="{
                height: '64px',
                marginTop: '-3px',
                marginBottom: '-2px'
            }"
        >
            <!-- # 포켓몬 이름 -->
            <v-autocomplete
                ref="pokemonAutocomplete"
                class="rounded-lg shrink"
                style="width:195px; margin-left:10px;"
                placeholder="포켓몬 선택"
                persistent-placeholder
                dense
                hide-details
                item-value="en"
                item-text="kr"
                :items="pokemon_list"
                v-model="pokemon.name"
                outlined
                :menu-props="{
                    offsetY: true,
                    maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                }"
                @change="loadPokemon()"
                @keydown.enter.native="handleEnter"
            ></v-autocomplete>

            <v-spacer></v-spacer>


            <div
                class="d-flex align-center"
                :style="{
                    marginTop: isMobileBrowser ? '1px' : '',
                }"
            >
                <!-- 검 -->
                <div
                    class="ml-2 mr-3"
                    style="width:24px; height:25px;"
                    @click="$vuetify.breakpoint.mobile? toggleMobileTab2() :''"
                >
                    <v-img
                        contain
                        width="24"
                        height="25"
                        src="@/assets/icons/attacker.png"
                    ></v-img>
                </div>

                <!-- 스피드 -->
                <div
                    :class="$store.state.is_admin? 'mr-1' : 'mr-4'"
                    style="width:14px; height:25px;"
                    @click="$vuetify.breakpoint.mobile? toggleMobileTab() : $store.commit('update_is_speed', true)"
                >
                    <v-img
                        contain
                        width="14"
                        height="25"
                        src="@/assets/icons/speed_off.png"
                    ></v-img>
                </div>

                <!-- 도감 -->
                <div
                    v-if="$store.state.is_admin"
                    class="ml-6px mr-3"
                    style="width:31px; height:25px;"
                    @click="$vuetify.breakpoint.mobile? toggleMobileTab3() : $store.commit('update_is_docs', !$store.state.is_docs)"
                >
                    <v-img
                        contain
                        width="31"
                        height="25"
                        src="@/assets/icons/docs.png"
                    ></v-img>
                </div>
            </div>
        </div>

        <div
            style="padding:0 10px;"
        >
            <div
                class="rounded-lg"
            >
                <div
                    style="height:25px; background:#434343; font-size:12px; cursor:pointer;"
                    :style="isMobileBrowser ? 'margin-bottom:-1px;' : 'padding-bottom:2px;'"
                    class="white--text text-center d-flex align-center justify-center pl-1"
                    :class="group.basic ? 'rounded-t-lg' : 'rounded-lg'"
                    @click="group.basic = !group.basic"
                >
                    기본정보
                    <v-icon v-if="group.basic" color="white" size="16" :style="isMobileBrowser ? 'margin-bottom:3px;' : ''">mdi-menu-up</v-icon>
                    <v-icon v-else color="white" size="16" :style="isMobileBrowser ? 'margin-bottom:3px;' : ''">mdi-menu-down</v-icon>
                </div>

                <table
                    v-if="group.basic"
                    class="docs_table"
                >
                    <tr>
                        <th>모습</th>
                        <th>이름</th>
                        <th>도감번호</th>
                    </tr>
                    <tr>
                        <td
                            style="width:120px; background:transparent"
                        >
                            <div class="d-flex align-center justify-center">
                                <v-img
                                    class="shrink"
                                    width="50"
                                    height="50"
                                    :src="pokemon.sprite_default"
                                ></v-img>
                                <v-img
                                    class="shrink"
                                    width="50"
                                    height="50"
                                    :src="pokemon.sprite_shiny"
                                ></v-img>
                            </div>
                        </td>
                        <td>
                            <div
                                class="d-flex align-center justify-center"
                            >
                                <div v-if="pokemon.name">
                                    <p class="mb-0" style="font-size:10px;"><span style="font-weight:500;">국문</span> {{ pokemon.name_kr.replace(/\s*\([^)]*\)/g, '').trim() }}</p>
                                    <p class="mb-0" style="font-size:10px;"><span style="font-weight:500;">영문</span> {{ pokemon.name.split('-')[0] }}</p>
                                    <p class="mb-0" style="font-size:10px;"><span style="font-weight:500;">일문</span> {{ pokemon.name_jp }}</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div v-if="!loading">
                                <div v-if="pokemon.pokedex_list.length">
                                    <div
                                        v-for="(item, index) in pokemon.pokedex_list" 
                                        :key="index"
                                    >
                                        <div v-if="item">
                                            <template v-if="item.text2">
                                                {{ item.text }}
                                                <sup v-if="item.extra">{{ item.extra }}</sup>
                                                {{ item.text2 }}
                                                <sup v-if="item.extra2">{{ item.extra2 }}</sup>
                                            </template>
                                            <template v-else>
                                                {{ item.text }}
                                                <sup v-if="item.extra">{{ item.extra }}</sup>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-else
                                    class="text-center"
                                >
                                    -
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <div
                v-if="group.basic"
                class="rounded-lg mt-10px"
            >
                <table
                    class="docs_table2"
                >
                    <tr>
                        <th style="border-top-left-radius: 8px;">분류</th>
                        <th>신장</th>
                        <th>체중</th>
                        <th>포획률</th>
                        <th style="border-top-right-radius: 8px;">타입</th>
                    </tr>
                    <tr>
                        <td><span v-if="pokemon.name">{{pokemon.genera_type}}</span></td>
                        <td style="min-width:33px;"><span v-if="pokemon.name">{{pokemon.height}}{{pokemon.height == "-"? "" : "m"}}</span></td>
                        <td style="min-width:33px;"><span v-if="pokemon.name">{{pokemon.weight}}{{pokemon.weight == "-"? "" : "kg"}}</span></td>
                        <td style="min-width:33px;"><span v-if="pokemon.name">{{pokemon.capture_rate}}</span></td>
                        <td>
                            <div
                                class="d-flex justify-center"
                            >
                                <div v-if="pokemon.types[0]" :style="caculate_type_color(pokemon.types[0])" style="width:32px; height:16px; font-size:10px; color:white;">{{pokemon.types[0]}}</div>
                                <div v-if="pokemon.types[1]" :style="caculate_type_color(pokemon.types[1])" style="width:32px; height:16px; font-size:10px; color:white; margin-left:4px;">{{pokemon.types[1]}}</div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <div
                v-if="group.basic"
                class="rounded-lg mt-10px"
            >
                <table
                    class="docs_table2"
                >
                    <tr>
                        <th style="border-top-left-radius: 8px;">특성</th>
                        <th style="border-top-right-radius: 8px;">이름</th>
                    </tr>
                    <tr>
                        <td>첫 번째 특성</td>
                        <td @click="selected_ability = pokemon?.abilities[0]; dialog.ability = true" style="cursor:pointer;">{{ pokemon.abilities[0]?.name || '-' }}</td>
                    </tr>
                    <tr>
                        <td>두 번째 특성</td>
                        <td @click="selected_ability = pokemon?.abilities[1]; dialog.ability = true" style="cursor:pointer;">{{ pokemon.abilities[1]?.name || '-' }}</td>
                    </tr>
                    <tr>
                        <td>숨겨진 특성</td>
                        <td @click="selected_ability = pokemon?.abilities[2]; dialog.ability = true" style="cursor:pointer;">{{ pokemon.abilities[2]?.name || '-' }}</td>
                    </tr>
                </table>
            </div>
            
            <div
                class="rounded-lg mt-10px"
            >
                <div
                    style="height:25px; background:#434343; font-size:12px; cursor:pointer;"
                    :style="isMobileBrowser ? 'margin-bottom:-1px;' : 'padding-bottom:2px;'"
                    class="white--text text-center d-flex align-center justify-center pl-1"
                    :class="group.stat ? 'rounded-t-lg' : 'rounded-lg'"
                    @click="group.stat = !group.stat"
                >
                    종족값 ({{pokemon.stats.hp + pokemon.stats.attack + pokemon.stats.defense + pokemon.stats.special_attack + pokemon.stats.special_defense + pokemon.stats.speed}}) 및 상성
                    <v-icon v-if="group.stat" color="white" size="16" :style="isMobileBrowser ? 'margin-bottom:3px;' : ''">mdi-menu-up</v-icon>
                    <v-icon v-else color="white" size="16" :style="isMobileBrowser ? 'margin-bottom:3px;' : ''">mdi-menu-down</v-icon>
                </div>

                <table
                    v-if="group.stat"
                    class="docs_table"
                >
                    <tr>
                        <th style="width:16.66%;">HP</th>
                        <th style="width:16.66%;">공격</th>
                        <th style="width:16.66%;">방어</th>
                        <th style="width:16.66%;">특수공격</th>
                        <th style="width:16.66%;">특수방어</th>
                        <th style="width:16.66%;">스피드</th>
                    </tr>
                    <tr class="text-center">
                        <td>{{pokemon.stats.hp}}</td>
                        <td>{{pokemon.stats.attack}}</td>
                        <td>{{pokemon.stats.defense}}</td>
                        <td>{{pokemon.stats.special_attack}}</td>
                        <td>{{pokemon.stats.special_defense}}</td>
                        <td>{{pokemon.stats.speed}}</td>
                    </tr>
                </table>
            </div>

            <div
                v-if="group.stat"
                class="rounded-lg mt-10px"
            >
                <table
                    class="docs_table2"
                >
                    <tr>
                        <th style="width:20%; border-top-left-radius: 8px;">배수</th>
                        <th style="width:80%; border-top-right-radius: 8px;">타입</th>
                    </tr>
                    <tr>
                        <td>4배</td>
                        <td>
                            <div v-if="pokemon.name" class="d-flex justify-center flex-wrap">
                                <div
                                    v-for="type in filteredTypeNames(4)" :key="type"
                                    :style="caculate_type_color(type)" 
                                    style="width:32px; height:16px; font-size:10px; color:white; margin-right:4px;"
                                >
                                    {{type}}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>2배</td>
                        <td>
                            <div v-if="pokemon.name" class="d-flex justify-center flex-wrap">
                                <div
                                    v-for="type in filteredTypeNames(2)" :key="type"
                                    :style="caculate_type_color(type)" 
                                    style="width:32px; height:16px; font-size:10px; color:white; margin-right:4px;"
                                >
                                    {{type}}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>1배</td>
                        <td>
                            <div v-if="pokemon.name" class="d-flex justify-center flex-wrap">
                                <div
                                    v-for="type in filteredTypeNames(1)" :key="type"
                                    :style="caculate_type_color(type)" 
                                    style="width:32px; height:16px; font-size:10px; color:white; margin-right:4px;"
                                >
                                    {{type}}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>0.5배</td>
                        <td>
                            <div v-if="pokemon.name" class="d-flex justify-center flex-wrap">
                                <div
                                    v-for="type in filteredTypeNames(0.5)" :key="type"
                                    :style="caculate_type_color(type)" 
                                    style="width:32px; height:16px; font-size:10px; color:white; margin-right:4px;"
                                >
                                    {{type}}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>0.25배</td>
                        <td>
                            <div v-if="pokemon.name" class="d-flex justify-center flex-wrap">
                                <div
                                    v-for="type in filteredTypeNames(0.25)" :key="type"
                                    :style="caculate_type_color(type)" 
                                    style="width:32px; height:16px; font-size:10px; color:white; margin-right:4px;"
                                >
                                    {{type}}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>0배</td>
                        <td>
                            <div v-if="pokemon.name" class="d-flex justify-center flex-wrap">
                                <div
                                    v-for="type in filteredTypeNames(0)" :key="type"
                                    :style="caculate_type_color(type)" 
                                    style="width:32px; height:16px; font-size:10px; color:white; margin-right:4px;"
                                >
                                    {{type}}
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <div
                class="rounded-lg mt-10px"
            >
                <div
                    style="height:25px; background:#434343; font-size:12px; cursor:pointer;"
                    :style="isMobileBrowser ? 'margin-bottom:-1px;' : 'padding-bottom:2px;'"
                    class="white--text text-center d-flex align-center justify-center pl-1"
                    :class="group.evolution ? 'rounded-t-lg' : 'rounded-lg'"
                    @click="group.evolution = !group.evolution"
                >
                    진화과정
                    <v-icon v-if="group.evolution" color="white" size="16" :style="isMobileBrowser ? 'margin-bottom:3px;' : ''">mdi-menu-up</v-icon>
                    <v-icon v-else color="white" size="16" :style="isMobileBrowser ? 'margin-bottom:3px;' : ''">mdi-menu-down</v-icon>
                </div>

                <div
                    v-if="group.evolution"
                >
                    <table
                        v-if="chain_list.length > 0"
                        class="docs_table"
                    >
                        <tr>
                            <th style="width:60px;">모습</th>
                            <th>정보</th>
                        </tr>
                        <tr v-for="item in chain_list" :key="item.pokemon_en">
                            <td
                                style="width:60px; background:transparent; border-bottom: 1px solid #ddd; cursor:pointer;"
                                @click="pokemon.name = item.pokemon_en; loadPokemon()"
                            >
                                <div class="d-flex align-center justify-center">
                                    <v-img
                                        class="shrink"
                                        width="50"
                                        height="50"
                                        :src="item.sprite"
                                    ></v-img>
                                </div>
                            </td>
                            <td
                                style="border-bottom: 1px solid #ddd; font-size:12px;"
                                class="text-center"
                            >
                                {{getPokemonKr(item.pokemon_en)}}

                                <p
                                    class="text-center mb-0"
                                    style="font-size:10px; margin-top:2px; white-space: pre-line"
                                    v-html="replaceTextToImg(item.content)"
                                ></p>
                            </td>
                        </tr>
                    </table>

                    <!-- 일반 -->
                    <!-- <div
                        v-if="!(pokemon.name.split('-')[0] == 'urshifu' || pokemon.name == 'kubfu')"
                    >
                        <table
                            v-if="chain_list.length > 0"
                            class="docs_table"
                        >
                            <tr>
                                <th style="width:60px;">모습</th>
                                <th>정보</th>
                            </tr>
                            <tr v-for="item in chain_list" :key="item.name">
                                <td
                                    style="width:60px; background:transparent; border-bottom: 1px solid #ddd; cursor:pointer;"
                                    @click="pokemon.name = item.name; loadPokemon()"
                                >
                                    <div class="d-flex align-center justify-center">
                                        <v-img
                                            class="shrink"
                                            width="50"
                                            height="50"
                                            :src="item.sprite"
                                        ></v-img>
                                    </div>
                                </td>
                                <td
                                    style="border-bottom: 1px solid #ddd; font-size:12px;"
                                    class="text-center"
                                >
                                    {{item.name_kr}}

                                    <div
                                        style="font-size:10px; margin-top:2px;"
                                    >
                                        <div v-if="item.evolution_details.length > 0">
                                            <div v-for="detail in item.evolution_details" :key="detail">
                                                {{detail}}
                                            </div>
                                        </div>
                                        <div v-else>
                                            초기형태
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div> -->

                    <!-- 치고마 or 우라오스 4종 -->
                    <!-- <div
                        v-else
                    >
                        <table
                            class="docs_table"
                        >
                            <tr>
                                <th style="width:60px;">모습</th>
                                <th>정보</th>
                            </tr>
                            <tr>
                                <td
                                    style="width:60px; background:transparent; border-bottom: 1px solid #ddd; cursor:pointer;"
                                    @click="pokemon.name = 'kubfu'; loadPokemon()"
                                >
                                    <div class="d-flex align-center justify-center">
                                        <v-img
                                            class="shrink"
                                            width="50"
                                            height="50"
                                            src="https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/891.png"
                                        ></v-img>
                                    </div>
                                </td>
                                <td
                                    style="border-bottom: 1px solid #ddd; font-size:12px;"
                                    class="text-center"
                                >
                                    치고마

                                    <div
                                        style="font-size:10px; margin-top:2px;"
                                    >
                                        초기형태
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="width:60px; background:transparent; border-bottom: 1px solid #ddd;"
                                    @click="pokemon.name = 'urshifu-single-strike'; loadPokemon()"
                                >
                                    <div class="d-flex align-center justify-center">
                                        <v-img
                                            class="shrink"
                                            width="50"
                                            height="50"
                                            src="https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/10191.png"
                                        ></v-img>
                                    </div>
                                </td>
                                <td
                                    style="border-bottom: 1px solid #ddd; font-size:12px;"
                                    class="text-center"
                                >
                                    우라오스 (일격)

                                    <div
                                        style="font-size:10px; margin-top:2px;"
                                    >
                                        악의 탑을 공략해 악의 족자를 보여줌(8세대)<br/>
                                        악의 족자를 사용(9세대) 진화
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="width:60px; background:transparent; border-bottom: 1px solid #ddd;"
                                    @click="pokemon.name = 'urshifu-rapid-strike'; loadPokemon()"
                                >
                                    <div class="d-flex align-center justify-center">
                                        <v-img
                                            class="shrink"
                                            width="50"
                                            height="50"
                                            src="https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/892.png"
                                        ></v-img>
                                    </div>
                                </td>
                                <td
                                    style="border-bottom: 1px solid #ddd; font-size:12px;"
                                    class="text-center"
                                >
                                    우라오스 (연격)

                                    <div
                                        style="font-size:10px; margin-top:2px;"
                                    >
                                        물의 탑을 공략해 물의 족자를 보여줌(8세대)<br/>
                                        물의 족자를 사용(9세대) 진화
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div> -->
                </div>
            </div>

            <div
                class="rounded-lg mt-10px"
            >
                <div
                    style="height:25px; background:#434343; font-size:12px; cursor:pointer;"
                    :style="isMobileBrowser ? 'margin-bottom:-1px;' : 'padding-bottom:2px;'"
                    class="white--text text-center d-flex align-center justify-center pl-1"
                    :class="group.docs_explain ? 'rounded-t-lg' : 'rounded-lg'"
                    @click="group.docs_explain = !group.docs_explain"
                >
                    도감설명
                    <v-icon v-if="group.docs_explain" color="white" size="16" :style="isMobileBrowser ? 'margin-bottom:3px;' : ''">mdi-menu-up</v-icon>
                    <v-icon v-else color="white" size="16" :style="isMobileBrowser ? 'margin-bottom:3px;' : ''">mdi-menu-down</v-icon>
                </div>

                <table
                    v-if="group.docs_explain"
                    class="docs_table"
                >
                    <tr>
                        <th style="width:60px;">버전명</th>
                        <th>설명</th>
                    </tr>
                    <tr
                        v-for="item in formatDocsExplain(pokemon.docs_explain)" :key="item.names"
                        class="text-center"
                    >
                        <td
                            style="border-bottom: 1px solid #ddd;"
                        >
                            <div class="d-flex justify-center flex-wrap mt-1">
                                <div
                                    v-for="name in item.names.split(',')" 
                                    :key="name"
                                    class="mx-1"
                                >
                                    <img 
                                        :src="require(`@/assets/version/${name.trim()}.png`)" 
                                        :alt="name"
                                        style="height: 14px; width:17px;"
                                    >
                                </div>
                            </div>
                        </td>
                        <td style="border-bottom: 1px solid #ddd;">{{ item.value }}</td>
                    </tr>
                </table>
            </div>

            <div
                class="rounded-lg mt-10px"
            >
                <div
                    style="height:25px; background:#434343; font-size:12px; cursor:pointer;"
                    :style="isMobileBrowser ? 'margin-bottom:-1px;' : 'padding-bottom:2px;'"
                    class="white--text text-center d-flex align-center justify-center pl-1"
                    :class="group.genetics ? 'rounded-t-lg' : 'rounded-lg'"
                    @click="group.genetics = !group.genetics"
                >
                    유전정보
                    <v-icon v-if="group.genetics" color="white" size="16" :style="isMobileBrowser ? 'margin-bottom:3px;' : ''">mdi-menu-up</v-icon>
                    <v-icon v-else color="white" size="16" :style="isMobileBrowser ? 'margin-bottom:3px;' : ''">mdi-menu-down</v-icon>
                </div>

                <table
                    v-if="group.genetics"
                    class="docs_table"
                >
                    <tr>
                        <th style="width:58px !important;">성비</th>
                        <th style="width:78px !important;">알 그룹</th>
                        <th style="width:58px !important; letter-spacing:-1px;">부화 카운트</th>
                        <th style="width:50px !important;">친밀도</th>
                        <th style="width:56px !important;">총 경험치</th>
                        <th style="width:44px !important;">도감 색</th>
                    </tr>
                    <tr class="text-center">
                        <td style="width:58px; padding:6px 0;">
                            <div v-if="pokemon.name">
                                <div v-if="pokemon.genetics.female == -12.5">
                                    <p class="mb-0" style="font-size:10px;">무성</p>
                                </div>
                                <div v-if="pokemon.genetics.female != -12.5">
                                    <p class="mb-0" style="font-size:10px;">♀ : {{ pokemon.genetics.female }}%</p>
                                    <p class="mb-0" style="font-size:10px;">♂ : {{ pokemon.genetics.male }}%</p>
                                </div>
                            </div>
                        </td>
                        <td style="width:78px; padding:6px 0;">
                            <span v-if="pokemon.name">{{pokemon.egg_groups.join(", ")}}</span>
                        </td>
                        <td style="padding:6px 0;"><span v-if="pokemon.name">{{$toComma(pokemon.hatch_counter)}}</span></td>
                        <td style="padding:6px 0;"><span v-if="pokemon.name">{{pokemon.base_happiness}}</span></td>
                        <td style="padding:6px 0;"><span v-if="pokemon.name">{{$toComma(pokemon.total_experience)}}</span></td>
                        <td style="padding:6px 0;"><span v-if="pokemon.name">{{pokemon.color}}</span></td>
                    </tr>
                </table>
            </div>
        </div>

        <v-dialog
            v-model="dialog.ability"
            content-class="rounded-10"
            width="320"
        >
            <v-sheet
                v-if="selected_ability"
                class="px-4 py-6"
                style="position:relative;"
            >
                <v-icon
                    style="position:absolute; top:12px; right:12px;"
                    color="grey"
                    @click="dialog.ability = false"
                >
                    mdi-close
                </v-icon>
                
                <p
                    style="font-size:14px; font-weight:bold;"
                    class="mb-3"
                >
                    {{selected_ability.name}}
                </p>
                <p
                    style="font-size:12px;"
                    class="mb-0"
                >
                    {{selected_ability.text}}
                </p>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
import common from '@/data/common.json'

export default {
    data: () => ({
        // 타입 목록
        type_list: common.type_list,

        // 포켓몬 목록
        pokemon_list: [],

        pokemon: {
            abilities: [],

            name: "",
            name_kr: "",
            name_jp: "",
            sprite_default: "",
            sprite_shiny: "",

            types: [],

            egg_groups: [],
            hatch_counter: 0,
            base_happiness: 0,
            total_experience: 0,
            color: "",

            height: 0,
            weight: 0,

            // 스탯
            stats: {
                // H
                hp: 0,

                // A
                attack: 0,

                // B
                defense: 0,

                // C
                special_attack: 0,

                // D
                special_defense: 0,

                // S
                speed: 0,
            },

            genera_type: "",
            capture_rate: 0,

            genetics: {
                female: 0,
                male: 0,
            },

            docs_explain: {},

            pokedex_list: []
        },

        type_counter_list: {
            노말: 1,
            불꽃: 1,
            물: 1,
            풀: 1,
            전기: 1,
            얼음: 1,
            격투: 1,
            독: 1,
            땅: 1,
            비행: 1,
            에스퍼: 1,
            벌레: 1,
            바위: 1,
            고스트: 1,
            드래곤: 1,
            악: 1,
            강철: 1,
            페어리: 1,
        },

        chain_list: [],

        loading: false,
        componentKey: 0,

        pokedex_list: [
            {
                pokedex: 'national',
                name_kr: '전국',
                name_extra: ''
            },
            {
                pokedex: 'kanto', 
                name_kr: '관동',
                name_extra: 'RGB'
            },
            {
                pokedex: 'original-johto',
                name_kr: '성도',
                name_extra: 'GSC'
            },
            {
                pokedex: 'hoenn',
                name_kr: '호연',
                name_extra: 'RSE'
            },
            {
                pokedex: 'original-sinnoh',
                name_kr: '신오',
                name_extra: 'BD'
            },
            {
                pokedex: 'extended-sinnoh',
                name_kr: '신오',
                name_extra: 'PT'
            },
            {
                pokedex: 'updated-johto',
                name_kr: '성도',
                name_extra: 'HGSS'
            },
            {
                pokedex: 'original-unova',
                name_kr: '하나',
                name_extra: 'BW'
            },
            {
                pokedex: 'updated-unova',
                name_kr: '하나',
                name_extra: 'BW2'
            },
            {
                pokedex: 'conquest-gallery',
                name_kr: '노부나가의 야망',
                name_extra: ''
            },
            {
                pokedex: 'kalos-central',
                name_kr: '칼로스',
                name_extra: '센트럴'
            },
            {
                pokedex: 'kalos-coastal',
                name_kr: '칼로스',
                name_extra: '코스트'
            },
            {
                pokedex: 'kalos-mountain',
                name_kr: '칼로스',
                name_extra: '마운틴'
            },
            {
                pokedex: 'updated-hoenn',
                name_kr: '호연',
                name_extra: 'ORAS'
            },
            {
                pokedex: 'original-alola',
                name_kr: '알로라',
                name_extra: 'SM'
            },
            {
                pokedex: 'original-melemele',
                name_kr: '알로라',
                name_extra: 'SM'
            },
            {
                pokedex: 'original-akala',
                name_kr: '알로라',
                name_extra: 'SM'
            },
            {
                pokedex: 'original-ulaula',
                name_kr: '알로라',
                name_extra: 'SM'
            },
            {
                pokedex: 'original-poni',
                name_kr: '알로라',
                name_extra: 'SM'
            },
            {
                pokedex: 'updated-alola',
                name_kr: '알로라',
                name_extra: 'USM'
            },
            {
                pokedex: 'updated-melemele',
                name_kr: '알로라',
                name_extra: 'USM'
            },
            {
                pokedex: 'updated-akala',
                name_kr: '알로라',
                name_extra: 'USM'
            },
            {
                pokedex: 'updated-ulaula',
                name_kr: '알로라',
                name_extra: 'USM'
            },
            {
                pokedex: 'updated-poni',
                name_kr: '알로라',
                name_extra: 'USM'
            },
            {
                pokedex: 'letsgo-kanto',
                name_kr: '관동',
                name_extra: 'LPLE'
            },
            {
                pokedex: 'galar',
                name_kr: '가라르',
                name_extra: '본토'
            },
            {
                pokedex: 'isle-of-armor',
                name_kr: '가라르',
                name_extra: '갑옷섬'
            },
            {
                pokedex: 'crown-tundra',
                name_kr: '가라르',
                name_extra: '왕관설원'
            },
            {
                pokedex: 'hisui',
                name_kr: '히스이',
                name_extra: ''
            },
            {
                pokedex: 'paldea',
                name_kr: '팔데아',
                name_extra: '본토'
            },
            {
                pokedex: 'kitakami',
                name_kr: '팔데아',
                name_extra: '북신'
            },
            {
                pokedex: 'blueberry',
                name_kr: '팔데아',
                name_extra: '블루베리'
            }
        ],

        selected_ability: {},

        dialog: {
            ability: false,
        },

        ability_list: [],

        name_addon: [
            "-galar",
            "-alola",
            "-hisui"
        ],

        group: {
            basic: true,
            stat: true,
            evolution: false,
            docs_explain: false,
            genetics: false,
        },

        textList: [
            // 추가
            "#쪽빛구슬",
            "#주홍구슬",
            "#운석",
            "#대장의징표",
            "#지가르데큐브",
            "#테라스탈",
            "#울트라네크로Z",

            "#수컷",
            "#암컷",
            "#기술",
            "#레벨업",
            "#절친하트",
            "#아름다움",
            "#친밀도",
            "#지도",
            "#이끼바위",
            "#얼음바위",
            "#비",
            "#낮",
            "#밤",
            "#황혼",
            "#통신교환",
            "#뒤집기",
            "#성격치",
            "#소지",
            "#다이맥스",
            "#그라시데아꽃",
            "#비추는거울",
            "#유전자쐐기",
            "#굴레의항아리",
            "#네크로플러스루나",
            "#네크로플러스솔",
            "#다홍꿀",
            "#보라꿀",
            "#연분홍꿀",
            "#진노랑꿀",
            "#녹슨검",
            "#녹슨방패",
            "#로토무카탈로그",
            "#유대의고삐",
            "#큰금강옥",
            "#큰백옥",
            "#큰금백옥",
            "#주춧돌의가면",
            "#우물의가면",
            "#화덕의가면",
            "#이상해꽃나이트",
            "#리자몽나이트X",
            "#리자몽나이트Y",
            "#거북왕나이트",
            "#후디나이트",
            "#팬텀나이트",
            "#캥카나이트",
            "#쁘사이저나이트",
            "#갸라도스나이트",
            "#프테라나이트",
            "#뮤츠나이트X",
            "#뮤츠나이트Y",
            "#전룡나이트",
            "#핫삼나이트",
            "#헤라크로스나이트",
            "#헬가나이트",
            "#마기라스나이트",
            "#번치코나이트",
            "#가디안나이트",
            "#입치트나이트",
            "#보스로라나이트",
            "#요가램나이트",
            "#썬더볼트나이트",
            "#다크펫나이트",
            "#앱솔나이트",
            "#한카리아스나이트",
            "#루카리오나이트",
            "#눈설왕나이트",
            "#독침붕나이트",
            "#피죤투나이트",
            "#야도란나이트",
            "#강철톤나이트",
            "#나무킹나이트",
            "#대짱이나이트",
            "#깜까미나이트",
            "#샤크니아나이트",
            "#폭타나이트",
            "#파비코리나이트",
            "#얼음귀신나이트",
            "#보만다나이트",
            "#메타그로스나이트",
            "#라티아스나이트",
            "#라티오스나이트",
            "#이어롭나이트",
            "#엘레이드나이트",
            "#다부니 나이트",
            "#디안시나이트",
            "#주홍구슬",
            "#쪽빛구슬",
            "#불꽃의돌",
            "#물의돌",
            "#천둥의돌",
            "#리프의돌",
            "#달의돌",
            "#태양의돌",
            "#빛의돌",
            "#어둠의돌",
            "#각성의돌",
            "#동글동글돌",
            "#얼음의돌",
            "#금속코트",
            "#업그레이드",
            "#왕의징표석",
            "#용의비늘",
            "#심해의비늘",
            "#심해의이빨",
            "#괴상한패치",
            "#마그마부스터",
            "#에레키부스터",
            "#영계의천",
            "#프로텍터",
            "#예리한손톱",
            "#예리한이빨",
            "#고운비늘",
            "#휘핑팝",
            "#향기주머니",
            "#깨진포트",
            "#이빠진포트",
            "#달콤한사과",
            "#새콤한사과",
            "#딸기사탕공예",
            "#가라두구팔찌",
            "#가라두구머리장식",
            "#연결의끈",
            "#검은휘석",
            "#피트블록",
            "#악의족자",
            "#물의족자",
            "#저주받은갑옷",
            "#축복받은갑옷",
            "#꿀맛사과",
            "#범작찻잔",
            "#걸작찻잔",
            "#복합금속",
        ],

        isMobileBrowser: false,
    }),

    watch: {
        // pokemon 객체의 변화를 깊은 감시로 모니터링
        pokemon: {
            handler() {
                this.$nextTick(() => {
                    // this.monitorHeight()
                })
            },
            deep: true
        },

        // type_counter_list의 변화를 깊은 감시로 모니터링 
        type_counter_list: {
            handler() {
                this.$nextTick(() => {
                    // this.monitorHeight()
                })
            },
            deep: true
        },

        docs_pokemon(newVal) {
            if(this.pokemon.name != newVal) {
                this.pokemon.name = newVal
                this.loadPokemon()
            }
        },

        "$store.state.docs_pokemon"(newVal) {
            if(this.pokemon.name != newVal) {
                this.pokemon.name = newVal
                this.loadPokemon()
            }
        }
    },

    async mounted(){
        // 포켓몬 목록 불러오기
        await this.loadPokemonList()
        this.loading = false

        await this.loadAbilityList()

        // if(this.$store.state.docs_pokemon != this.pokemon.name) {
            this.pokemon.name = this.$store.state.docs_pokemon
            this.loadPokemon()
        // }

        this.checkMobileBrowser()

        // 컴포넌트 높이 모니터링 설정
        // this.monitorHeight()
        
        // resize 이벤트에 대한 리스너 추가
        // window.addEventListener('resize', this.monitorHeight)
    },

    beforeDestroy() {
        // 컴포넌트가 제거될 때 리스너 제거
        // window.removeEventListener('resize', this.monitorHeight) 
    },

    methods: {
        checkMobileBrowser() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera
            
            // 모바일 브라우저 체크를 위한 정규식
            const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i

            this.isMobileBrowser = mobileRegex.test(userAgent.toLowerCase())
        },

        // 영어 이름으로 한글 이름 찾기
        getPokemonKr(en) {
            const pokemon = this.pokemon_list.find(p => p.en === en)
            return pokemon ? pokemon.kr : ''
        },

        handleEnter(e) {
            const currentValue = e.target.value
            if (currentValue) {
                // 한글 이름으로 영어 이름 찾기
                const pokemon = this.pokemon_list.find(p => p.kr === currentValue)
                if (pokemon) {
                    this.pokemon.name = pokemon.en // 영어 이름으로 설정
                    this.loadPokemon()
                    this.$refs.pokemonAutocomplete.blur()
                } else {
                    // 매칭되는 포켓몬이 없을 경우 직접 입력값 사용
                    this.pokemon.name = currentValue 
                    this.loadPokemon()
                    this.$refs.pokemonAutocomplete.blur()
                }
            }
        },

        async loadAbilityList() {
            await this.$http.post("/api/ability/select")
            .then(async(res) => {
                this.ability_list = res.data
            })
        },

        toggleMobileTab3() {
            if (this.$store.state.mobile_tab === 'docs_left') {
                this.$store.commit('update_mobile_tab', 'docs_right')
            } else {
                this.$store.commit('update_mobile_tab', 'docs_left')
            }
        },

        toggleMobileTab2() {
            if (this.$store.state.mobile_tab === 'speed_defender') {
                this.$store.commit('update_mobile_tab', 'speed_attacker')
            } else {
                this.$store.commit('update_mobile_tab', 'attacker')
            }
        },

        toggleMobileTab() {
            if (this.$store.state.mobile_tab === 'speed_defender') {
                this.$store.commit('update_mobile_tab', 'defender')
            } else {
                this.$store.commit('update_mobile_tab', 'speed_defender')
            }
        },

        async formatPokedexNumbers(pokedex_numbers) {
            if (!pokedex_numbers) return []  // 데이터가 없을 경우 빈 배열 반환
    
            // 필터링할 도감 목록
            const excludePokedex = [
                'conquest-gallery',
                'original-melemele',
                'original-akala', 
                'original-ulaula',
                'original-poni',
                'updated-melemele',
                'updated-akala',
                'updated-ulaula', 
                'updated-poni'
            ]

            // 필터링 적용
            const filteredNumbers = pokedex_numbers.filter(entry => 
                !excludePokedex.includes(entry.pokedex.name)
            )

            // 도감 번호와 이름을 매핑
            const entries = filteredNumbers.map(entry => {
                const pokedexInfo = this.pokedex_list.find(p => p.pokedex === entry.pokedex.name)
                return {
                    id: `${entry.pokedex.name}-${entry.entry_number}`, // 고유한 key 값 생성
                    name_kr: pokedexInfo ? pokedexInfo.name_kr : entry.pokedex.name,
                    entry_number: entry.entry_number,
                    name_extra: pokedexInfo ? pokedexInfo.name_extra : ''
                }
            })

            // 번역명 기준으로 그룹화
            const groupedByName = entries.reduce((acc, curr) => {
                if (!acc[curr.name_kr]) {
                    acc[curr.name_kr] = []
                }
                acc[curr.name_kr].push(curr)
                return acc
            }, {})

            // 결과 배열 생성
            return Object.entries(groupedByName).map(([name_kr, items]) => {
                if (items.length === 1) {
                    // 중복되지 않은 경우
                    return {
                        id: items[0].id, // 고유 key 값 추가
                        text: `${name_kr} ${items[0].entry_number}`,
                        extra: items[0].name_extra
                    }
                } else if (items.length === 2) {
                    const first = items[0]
                    const second = items[1]
                    
                    if (first.entry_number === second.entry_number) {
                        return {
                            id: items[0].id, // 고유 key 값 추가
                            text: `${name_kr} ${first.entry_number}`,
                            extra: `${first.name_extra} / ${second.name_extra}`
                        }
                    } else {
                        return {
                            id: items[0].id, // 고유 key 값 추가
                            text: `${name_kr} ${first.entry_number}`,
                            extra: first.name_extra,
                            text2: ` ${second.entry_number}`,
                            extra2: second.name_extra
                        }
                    }
                }
            })
        },

        // 컴포넌트 높이 모니터링
        // monitorHeight() {
        //     const element = this.$el
        //     const height = element.offsetHeight

        //     // console.log(element)

        //     this.$store.commit('update_docs_height', height)
            
        //     // console.log('현재 컴포넌트 높이:', height)
        //     // if (height > 882) {
        //         // console.log('현재 컴포넌트 높이:', height)
        //     // }
        // },

        // 포켓몬 목록 불러오기
        async loadPokemonList() {
            await this.$http.post("/api/pokemon/select")
            .then(async(res) => {
                this.pokemon_list = res.data
            })
        },

        // # 타입상성 계산
        caculate_type_counter(){
            // 타입 상성 초기화
            this.type_counter_list = {
                노말: 1,
                불꽃: 1,
                물: 1,
                풀: 1,
                전기: 1,
                얼음: 1,
                격투: 1,
                독: 1,
                땅: 1,
                비행: 1,
                에스퍼: 1,
                벌레: 1,
                바위: 1,
                고스트: 1,
                드래곤: 1,
                악: 1,
                강철: 1,
                페어리: 1,
            }

            if(this.pokemon.types[0] == "강철") {
                this.type_counter_list.노말 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.얼음 *= 0.5
                this.type_counter_list.비행 *= 0.5
                this.type_counter_list.에스퍼 *= 0.5
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.바위 *= 0.5
                this.type_counter_list.드래곤 *= 0.5
                this.type_counter_list.강철 *= 0.5
                this.type_counter_list.페어리 *= 0.5
                this.type_counter_list.격투 *= 2
                this.type_counter_list.땅 *= 2
                this.type_counter_list.불꽃 *= 2

                // 수동 추가
                this.type_counter_list.독 *= 0
            }
            else if(this.pokemon.types[0] == "바위") {
                this.type_counter_list.노말 *= 0.5
                this.type_counter_list.독 *= 0.5
                this.type_counter_list.비행 *= 0.5
                this.type_counter_list.불꽃 *= 0.5
                this.type_counter_list.격투 *= 2
                this.type_counter_list.땅 *= 2
                this.type_counter_list.강철 *= 2
                this.type_counter_list.물 *= 2
                this.type_counter_list.풀 *= 2
            }
            else if(this.pokemon.types[0] == "고스트") {
                this.type_counter_list.노말 *= 0
                this.type_counter_list.격투 *= 0
                this.type_counter_list.독 *= 0.5
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.고스트 *= 2
                this.type_counter_list.악 *= 2
            }
            else if(this.pokemon.types[0] == "불꽃") {
                this.type_counter_list.불꽃 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.얼음 *= 0.5
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.강철 *= 0.5
                this.type_counter_list.페어리 *= 0.5
                this.type_counter_list.땅 *= 2
                this.type_counter_list.바위 *= 2
                this.type_counter_list.물 *= 2
            }
            else if(this.pokemon.types[0] == "물") {
                this.type_counter_list.불꽃 *= 0.5
                this.type_counter_list.물 *= 0.5
                this.type_counter_list.얼음 *= 0.5
                this.type_counter_list.강철 *= 0.5
                this.type_counter_list.전기 *= 2
                this.type_counter_list.풀 *= 2
            }
            else if(this.pokemon.types[0] == "풀") {
                this.type_counter_list.물 *= 0.5
                this.type_counter_list.전기 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.땅 *= 0.5
                this.type_counter_list.불꽃 *= 2
                this.type_counter_list.얼음 *= 2
                this.type_counter_list.독 *= 2
                this.type_counter_list.비행 *= 2
                this.type_counter_list.벌레 *= 2
            }
            else if(this.pokemon.types[0] == "전기") {
                this.type_counter_list.전기 *= 0.5
                this.type_counter_list.비행 *= 0.5
                this.type_counter_list.강철 *= 0.5
                this.type_counter_list.땅 *= 2
            }
            else if(this.pokemon.types[0] == "얼음") {
                this.type_counter_list.얼음 *= 0.5
                this.type_counter_list.불꽃 *= 2
                this.type_counter_list.격투 *= 2
                this.type_counter_list.바위 *= 2
                this.type_counter_list.강철 *= 2
            }
            else if(this.pokemon.types[0] == "격투") {
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.바위 *= 0.5
                this.type_counter_list.악 *= 0.5
                this.type_counter_list.비행 *= 2
                this.type_counter_list.에스퍼 *= 2
                this.type_counter_list.페어리 *= 2
            }
            else if(this.pokemon.types[0] == "독") {
                this.type_counter_list.격투 *= 0.5
                this.type_counter_list.독 *= 0.5
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.페어리 *= 0.5
                this.type_counter_list.땅 *= 2
                this.type_counter_list.에스퍼 *= 2
            }
            else if(this.pokemon.types[0] == "땅") {
                this.type_counter_list.독 *= 0.5
                this.type_counter_list.바위 *= 0.5
                this.type_counter_list.물 *= 2
                this.type_counter_list.풀 *= 2
                this.type_counter_list.얼음 *= 2

                // 수동 추가
                this.type_counter_list.전기 *= 0
            }
            else if(this.pokemon.types[0] == "비행") {
                this.type_counter_list.격투 *= 0.5
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.전기 *= 2
                this.type_counter_list.얼음 *= 2
                this.type_counter_list.바위 *= 2

                // 수동 추가
                this.type_counter_list.땅 *= 0
            }
            else if(this.pokemon.types[0] == "에스퍼") {
                this.type_counter_list.격투 *= 0.5
                this.type_counter_list.에스퍼 *= 0.5
                this.type_counter_list.벌레 *= 2
                this.type_counter_list.고스트 *= 2
                this.type_counter_list.악 *= 2
            }
            else if(this.pokemon.types[0] == "벌레") {
                this.type_counter_list.격투 *= 0.5
                this.type_counter_list.땅 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.불꽃 *= 2
                this.type_counter_list.비행 *= 2
                this.type_counter_list.바위 *= 2
            }
            else if(this.pokemon.types[0] == "드래곤") {
                this.type_counter_list.불꽃 *= 0.5
                this.type_counter_list.물 *= 0.5
                this.type_counter_list.전기 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.얼음 *= 2
                this.type_counter_list.드래곤 *= 2
                this.type_counter_list.페어리 *= 2
            }
            else if(this.pokemon.types[0] == "악") {
                this.type_counter_list.고스트 *= 0.5
                this.type_counter_list.악 *= 0.5
                this.type_counter_list.격투 *= 2
                this.type_counter_list.벌레 *= 2
                this.type_counter_list.페어리 *= 2

                // 수동 추가
                this.type_counter_list.에스퍼 *= 0
            }
            else if(this.pokemon.types[0] == "페어리") {
                this.type_counter_list.격투 *= 0.5
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.악 *= 0.5
                this.type_counter_list.독 *= 2
                this.type_counter_list.강철 *= 2

                // 수동 추가
                this.type_counter_list.드래곤 *= 0
            }
            // 수동 추가
            else if(this.pokemon.types[0] == "노말") {
                this.type_counter_list.격투 *= 2
                this.type_counter_list.고스트 *= 0
            }

            if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "강철") {
                this.type_counter_list.노말 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.얼음 *= 0.5
                this.type_counter_list.비행 *= 0.5
                this.type_counter_list.에스퍼 *= 0.5
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.바위 *= 0.5
                this.type_counter_list.드래곤 *= 0.5
                this.type_counter_list.강철 *= 0.5
                this.type_counter_list.페어리 *= 0.5
                this.type_counter_list.격투 *= 2
                this.type_counter_list.땅 *= 2
                this.type_counter_list.불꽃 *= 2

                // 수동 추가
                this.type_counter_list.독 *= 0
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "바위") {
                this.type_counter_list.노말 *= 0.5
                this.type_counter_list.독 *= 0.5
                this.type_counter_list.비행 *= 0.5
                this.type_counter_list.불꽃 *= 0.5
                this.type_counter_list.격투 *= 2
                this.type_counter_list.땅 *= 2
                this.type_counter_list.강철 *= 2
                this.type_counter_list.물 *= 2
                this.type_counter_list.풀 *= 2
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "고스트") {
                this.type_counter_list.노말 *= 0
                this.type_counter_list.격투 *= 0
                this.type_counter_list.독 *= 0.5
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.고스트 *= 2
                this.type_counter_list.악 *= 2
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "불꽃") {
                this.type_counter_list.불꽃 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.얼음 *= 0.5
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.강철 *= 0.5
                this.type_counter_list.페어리 *= 0.5
                this.type_counter_list.땅 *= 2
                this.type_counter_list.바위 *= 2
                this.type_counter_list.물 *= 2
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "물") {
                this.type_counter_list.불꽃 *= 0.5
                this.type_counter_list.물 *= 0.5
                this.type_counter_list.얼음 *= 0.5
                this.type_counter_list.강철 *= 0.5
                this.type_counter_list.전기 *= 2
                this.type_counter_list.풀 *= 2
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "풀") {
                this.type_counter_list.물 *= 0.5
                this.type_counter_list.전기 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.땅 *= 0.5
                this.type_counter_list.불꽃 *= 2
                this.type_counter_list.얼음 *= 2
                this.type_counter_list.독 *= 2
                this.type_counter_list.비행 *= 2
                this.type_counter_list.벌레 *= 2
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "전기") {
                this.type_counter_list.전기 *= 0.5
                this.type_counter_list.비행 *= 0.5
                this.type_counter_list.강철 *= 0.5
                this.type_counter_list.땅 *= 2
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "얼음") {
                this.type_counter_list.얼음 *= 0.5
                this.type_counter_list.불꽃 *= 2
                this.type_counter_list.격투 *= 2
                this.type_counter_list.바위 *= 2
                this.type_counter_list.강철 *= 2
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "격투") {
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.바위 *= 0.5
                this.type_counter_list.악 *= 0.5
                this.type_counter_list.비행 *= 2
                this.type_counter_list.에스퍼 *= 2
                this.type_counter_list.페어리 *= 2
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "독") {
                this.type_counter_list.격투 *= 0.5
                this.type_counter_list.독 *= 0.5
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.페어리 *= 0.5
                this.type_counter_list.땅 *= 2
                this.type_counter_list.에스퍼 *= 2
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "땅") {
                this.type_counter_list.독 *= 0.5
                this.type_counter_list.바위 *= 0.5
                this.type_counter_list.물 *= 2
                this.type_counter_list.풀 *= 2
                this.type_counter_list.얼음 *= 2

                // 수동 추가
                this.type_counter_list.전기 *= 0
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "비행") {
                this.type_counter_list.격투 *= 0.5
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.전기 *= 2
                this.type_counter_list.얼음 *= 2
                this.type_counter_list.바위 *= 2

                // 수동 추가
                this.type_counter_list.땅 *= 0
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "에스퍼") {
                this.type_counter_list.격투 *= 0.5
                this.type_counter_list.에스퍼 *= 0.5
                this.type_counter_list.벌레 *= 2
                this.type_counter_list.고스트 *= 2
                this.type_counter_list.악 *= 2
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "벌레") {
                this.type_counter_list.격투 *= 0.5
                this.type_counter_list.땅 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.불꽃 *= 2
                this.type_counter_list.비행 *= 2
                this.type_counter_list.바위 *= 2
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "드래곤") {
                this.type_counter_list.불꽃 *= 0.5
                this.type_counter_list.물 *= 0.5
                this.type_counter_list.전기 *= 0.5
                this.type_counter_list.풀 *= 0.5
                this.type_counter_list.얼음 *= 2
                this.type_counter_list.드래곤 *= 2
                this.type_counter_list.페어리 *= 2
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "악") {
                this.type_counter_list.고스트 *= 0.5
                this.type_counter_list.악 *= 0.5
                this.type_counter_list.격투 *= 2
                this.type_counter_list.벌레 *= 2
                this.type_counter_list.페어리 *= 2

                // 수동 추가
                this.type_counter_list.에스퍼 *= 0
            }
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "페어리") {
                this.type_counter_list.격투 *= 0.5
                this.type_counter_list.벌레 *= 0.5
                this.type_counter_list.악 *= 0.5
                this.type_counter_list.독 *= 2
                this.type_counter_list.강철 *= 2

                // 수동 추가
                this.type_counter_list.드래곤 *= 0
            }
            // 수동 추가
            else if(this.pokemon.types.length > 1 && this.pokemon.types[1] == "노말") {
                this.type_counter_list.격투 *= 2
                this.type_counter_list.고스트 *= 0
            }
        },

        // ... existing methods ...
        caculate_type_color(type) {
            const typeColors = {
                노말: '#949495',
                불꽃: '#e56c3e',
                물: '#5185c5',
                풀: '#66a945',
                전기: '#fbb917',
                얼음: '#6dc8eb',
                격투: '#e09c40',
                독: '#735198',
                땅: '#9c7743',
                비행: '#a2c3e7',
                에스퍼: '#dd6b7b',
                벌레: '#9fa244',
                바위: '#bfb889',
                고스트: '#684870',
                드래곤: '#535ca8',
                악: '#4c4948',
                강철: '#69a9c7',
                페어리: '#dab4d4'
            }
            return {
                backgroundColor: typeColors[type] || '#ffffff',
                marginTop: this.isMobileBrowser ? '0px' : '2px',
                marginBottom: this.isMobileBrowser ? '2px' : '0px',
                paddingTop: this.isMobileBrowser ? '1px' : '0px',
            } // 기본값은 흰색
        },

        filteredTypeNames(number) {
            return Object.keys(this.type_counter_list).filter(key => this.type_counter_list[key] === number)
        },

        formatDocsExplain(docs) {
            if (!docs) return []
            
            // pokemon_id 제외하고 복사
            const { pokemon_id, ...docsWithoutId } = docs
            
            // null이 아닌 값들을 value 기준으로 그룹화
            const groupedByValue = Object.entries(docsWithoutId).reduce((acc, [key, value]) => {
                // if (value === null) return acc
                
                // if (!acc[value]) {
                //     acc[value] = []
                // }
                // acc[value].push(key)
                // return acc

                // null, undefined, 빈 문자열(""), 공백 문자열(" ") 체크
                if (!value || value.trim() === "") return acc
                
                if (!acc[value]) {
                    acc[value] = []
                }
                acc[value].push(key)
                return acc
            }, {})
            
            // 그룹화된 결과를 원하는 형태의 배열로 변환
            return Object.entries(groupedByValue).map(([value, keys]) => ({
                names: keys.join(', '),
                value: value
            }))
        },

        // 포켓몬(pokemon) 선택 불러오기
        loadPokemon: _.debounce(async function(e) {
            this.loading = true
            this.$store.state.docs_pokemon = this.pokemon.name



            this.loading = true

            // pokemon_list에서 해당 포켓몬 찾기
            let pokemon = this.pokemon_list.find(p => p.en === this.pokemon.name)
            
            if (pokemon) {
                this.pokemon.name_kr = pokemon.kr
                this.pokemon.name_jp = pokemon.jp
                // 포켓몬 이미지
                this.pokemon.sprite_default = pokemon.sprite
                this.pokemon.sprite_shiny = pokemon.sprite_shiny

                // 포켓몬 타입
                this.pokemon.types = []
                if (pokemon.type1) this.pokemon.types.push(pokemon.type1)
                if (pokemon.type2) this.pokemon.types.push(pokemon.type2)

                await this.caculate_type_counter()

                // 포켓몬 스탯
                this.pokemon.stats.hp = pokemon.h
                this.pokemon.stats.attack = pokemon.a
                this.pokemon.stats.defense = pokemon.b
                this.pokemon.stats.special_attack = pokemon.c
                this.pokemon.stats.special_defense = pokemon.d
                this.pokemon.stats.speed = pokemon.s
            }

            await this.$http.get("https://pokeapi.co/api/v2/pokemon/"+this.pokemon.name)
            .then(async(res) => {
                console.log("pokemon", res.data)

                this.$http.post("/api/docs_explain/select/specific", {
                    params: {
                        pokemon_name: this.pokemon.name
                    }
                }).then(async(res) => {
                    console.log("docs_explain", res.data[0])
                    this.pokemon.docs_explain = res.data[0]
                })

                // 포켓몬 이미지
                this.pokemon.sprite_default = res.data.sprites.front_default
                this.pokemon.sprite_shiny = res.data.sprites.front_shiny


                this.pokemon.height = (res.data.height * 1/10).toFixed(1)
                this.pokemon.weight = (res.data.weight * 1/10).toFixed(1)


                const foundPokemon = this.pokemon_list.find(e => e.en === this.pokemon.name)
                if (foundPokemon) {
                    this.pokemon.name_kr = foundPokemon.kr
                    this.pokemon.name_jp = foundPokemon.jp
                }

                // 포켓몬 타입 (아예 동기처리 안함)
                // this.pokemon.types = []
                // await res.data.types.forEach(async(type) => {
                //     await this.$http.get(type.type.url)
                //     .then(async(res) => {
                //         await this.pokemon.types.push(res.data.names[1].name)
                //     })
                // })


                // await this.caculate_type_counter()
                
                // 포켓몬 타입 (순서 바뀔수 있음)
                // this.pokemon.types = []
                // const typePromises = res.data.types.map(type => 
                //     this.$http.get(type.type.url)
                //     .then(async res => {
                //         await this.pokemon.types.push(res.data.names[1].name)
                //     })
                // )

                // await Promise.all(typePromises)

                // 포켓몬 타입 (순서 동기처리 다시) (최종였던것)
                // this.pokemon.types = []
                // const typePromises = res.data.types.map(type => 
                //     this.$http.get(type.type.url)
                //     .then(res => res.data.names[1].name)
                // )

                // await Promise.all(typePromises).then(types => {
                //     this.pokemon.types = types
                // })



                // await this.caculate_type_counter()

                // 포켓몬 스탯
                // this.pokemon.stats.hp = res.data.stats[0].base_stat
                // this.pokemon.stats.attack = res.data.stats[1].base_stat
                // this.pokemon.stats.defense = res.data.stats[2].base_stat
                // this.pokemon.stats.special_attack = res.data.stats[3].base_stat
                // this.pokemon.stats.special_defense = res.data.stats[4].base_stat
                // this.pokemon.stats.speed = res.data.stats[5].base_stat

                // # abilities 배열의 각 URL을 비동기적으로 처리
                Promise.all(res.data.abilities.map(e => this.$http.get(e.ability.url)))
                .then(responses => {
                    console.log("ability", responses)
                    // slot 번호와 특성 이름, 설명을 함께 저장할 배열 생성
                    // const abilitiesWithSlot = responses.map((response, index) => {

                    //     // const nameObj = response.data.names.find(name => name.language.name === "ko") (한글이름)
                    //     const nameObj = response.data.name

                    //     // 한국어 설명 찾기
                    //     // const flavorText = response.data.flavor_text_entries
                    //     //     .filter(entry => entry.language.name === "ko")
                    //     //     .pop()?.flavor_text || null

                    //     return {
                    //         slot: res.data.abilities[index].slot,  // 원본 데이터의 slot 번호
                    //         name: nameObj,
                    //         // text: flavorText
                    //     }
                    // })

                    const abilitiesWithSlot = responses.map((response, index) => {
                        const nameEn = response.data.name
                        // ability_list에서 해당하는 한글 이름과 설명 찾기
                        const ability = this.ability_list.find(a => a.name_en === nameEn)
                        
                        return {
                            slot: res.data.abilities[index].slot,  // 원본 데이터의 slot 번호
                            name: ability ? ability.name_kr : nameEn, // 한글 이름이 있으면 사용, 없으면 영문
                            text: ability ? ability.text_kr : '' // 한글 설명
                        }
                    })

                    // slot 번호로 정렬된 특성 이름과 설명만 추출
                    this.pokemon.abilities = new Array(3).fill(null)  // 3개의 null로 초기화
                    abilitiesWithSlot.forEach(ability => {
                        this.pokemon.abilities[ability.slot - 1] = {
                            name: ability.name,
                            text: ability.text
                        }
                    })
                })

                await this.$http.get(res.data.species.url)
                .then(async(res) => {
                    console.log("species", res.data)

                    this.pokemon.pokedex_list = await this.formatPokedexNumbers(res.data.pokedex_numbers)

                    this.pokemon.genera_type = res.data.genera[1].genus
                    this.pokemon.hatch_counter = res.data.hatch_counter * 256
                    this.pokemon.base_happiness = res.data.base_happiness

                    // egg_groups 배열의 각 URL을 비동기적으로 처리
                    Promise.all(res.data.egg_groups.map(group => this.$http.get(group.url)))
                    .then(responses => {
                        this.pokemon.egg_groups = responses.map(response => {
                            const nameObj = response.data.names.find(name => name.language.name === "ko")
                            return nameObj ? nameObj.name : null
                        })
                    })

                    this.$http.get(res.data.growth_rate.url)
                    .then((res) => {
                        this.pokemon.total_experience = res.data.levels[99].experience
                    })

                    this.$http.get(res.data.color.url)
                    .then((res) => {
                        const nameObj = res.data.names.find(name => name.language.name === "ko")
                        this.pokemon.color = nameObj ? nameObj.name : null
                    })

                    this.pokemon.capture_rate = res.data.capture_rate
                    this.pokemon.genetics.female = res.data.gender_rate * 12.5
                    this.pokemon.genetics.male = 100 - (res.data.gender_rate * 12.5)

                    // # 진화 과정
                    // this.$http.get(res.data.evolution_chain.url)
                    // .then(async(res) => {
                    //     let chain = res.data.chain
                    //     console.log("진화 체인", await this.getEvolutionChain(chain))
                    //     this.chain_list = await this.getEvolutionChain(chain)
                    // })

                    this.chain_list = []
                    this.$http.post('/api/evolution_chain/select/specific', {
                        params: {
                            pokemon_en: this.pokemon.name
                        }
                    })
                    .then((res) => {
                        if(res.data.length){
                            this.chain_list = res.data
                        }
                    })


                    this.loading = false
                })
            }).catch((error) => {
                console.log("api에 없음", error)
                this.pokemon.genera_type = "-"
                this.pokemon.genetics.female = 0
                this.pokemon.genetics.male = 0
                this.pokemon.hatch_counter = 0
                this.pokemon.base_happiness = 0
                this.pokemon.total_experience = 0
                this.pokemon.egg_groups = ["-"]
                this.pokemon.color = "-"
                this.pokemon.pokedex_list = []
                this.pokemon.abilities = []
                this.chain_list = []
                this.pokemon.height = "-"
                this.pokemon.weight = "-"
                this.pokemon.capture_rate = "-"

                this.$http.post('/api/evolution_chain/select/specific', {
                    params: {
                        pokemon_en: this.pokemon.name
                    }
                })
                .then((res) => {
                    if(res.data.length){
                        this.chain_list = res.data
                    }
                })
                this.$http.post("/api/docs_explain/select/specific", {
                    params: {
                        pokemon_name: this.pokemon.name
                    }
                }).then(async(res) => {
                    console.log("docs_explain", res.data[0])
                    this.pokemon.docs_explain = res.data[0]
                })
            }).finally(() => {
                this.loading = false
            })
        }, 100, { leading: true, trailing: false }),

        // async getEvolutionChain(chain) {
        //     let evolutionArr = []
            
        //     const processChain = async (chainData, level = 0) => {
        //         // pokemon_list에서 해당하는 포켓몬 찾기
        //         const foundPokemon = this.pokemon_list.find(p => p.en === chainData.species.name)

        //         // 스프라이트와 이름 처리 로직
        //         let spriteUrl, pokemonName
        //         const currentPokemonName = this.pokemon.name
        //         const regionalForm = this.name_addon.find(addon => currentPokemonName.includes(addon))
                
        //         if (regionalForm) {
        //             // 지역 폼이 있는 경우
        //             const regionalPokemonName = `${chainData.species.name}${regionalForm}`
        //             try {
        //                 // 지역 폼 데이터 시도
        //                 const regionalRes = await this.$http.get(`https://pokeapi.co/api/v2/pokemon/${regionalPokemonName}`)
        //                 spriteUrl = regionalRes.data.sprites.front_default
        //                 pokemonName = regionalPokemonName
        //             } catch (error) {
        //                 // 지역 폼이 없으면 기본 폼으로 fallback
        //                 const defaultRes = await this.$http.get(`https://pokeapi.co/api/v2/pokemon/${chainData.species.name}`)
        //                 spriteUrl = defaultRes.data.sprites.front_default
        //                 pokemonName = chainData.species.name
        //             }
        //         } else {
        //             // 일반적인 경우
        //             const defaultRes = await this.$http.get(`https://pokeapi.co/api/v2/pokemon/${chainData.species.name}`)
        //             spriteUrl = defaultRes.data.sprites.front_default
        //             pokemonName = chainData.species.name
        //         }

        //         // pokemon_list에서 해당하는 포켓몬 찾기 (지역 폼 이름으로 다시 검색)
        //         const finalPokemon = this.pokemon_list.find(p => p.en === pokemonName) || foundPokemon
                
        //         // 진화 조건 포맷팅
        //         let formattedDetails = []
        //         if (chainData.evolution_details && chainData.evolution_details.length > 0) {
        //             formattedDetails = await Promise.all(
        //                 chainData.evolution_details.map(detail => this.formatEvolutionDetail(detail))
        //             )
        //         }
                
        //         const currentPokemon = {
        //             name: pokemonName,
        //             name_kr: finalPokemon ? finalPokemon.kr : '', 
        //             name_jp: finalPokemon ? finalPokemon.jp : '',
        //             level,
        //             sprite: spriteUrl,
        //             evolution_details: formattedDetails
        //         }
        //         evolutionArr.push(currentPokemon)

        //         if (chainData.evolves_to && chainData.evolves_to.length > 0) {
        //             await Promise.all(chainData.evolves_to.map(evolution => 
        //                 processChain(evolution, level + 1)
        //             ))
        //         }
        //     }

        //     await processChain(chain)
        //     return evolutionArr
        // },

        async formatEvolutionDetail(detail) {
            // console.log("detail", detail)

            let result = []

            // gender
            if (detail.gender !== null) {
                const genderMap = {
                    0: '암컷',
                    1: '수컷',
                    2: '무성'
                }
                result.push(`성별이 ${genderMap[detail.gender]} 일 경우`)
            }

            // held_item
            if (detail.held_item?.url) {
                await this.$http.get(detail.held_item.url)
                .then(res => {
                    const nameObj = res.data.names.find(name => name.language.name === "ko")
                    result.push(`${nameObj ? nameObj.name : ''} 아이템을 지닌 채`)
                })
            }

            // item
            if (detail.item?.url) {
                await this.$http.get(detail.item.url)
                .then(res => {
                    const nameObj = res.data.names.find(name => name.language.name === "ko")
                    result.push(`${nameObj ? nameObj.name : ''} 아이템을`)
                })
            }

            // known_move
            if (detail.known_move?.url) {
                await this.$http.get(detail.known_move.url)
                .then(res => {
                    const nameObj = res.data.names.find(name => name.language.name === "ko")
                    result.push(`${nameObj ? nameObj.name : ''} 기술을 배운 상태에서`)
                })
            }

            // known_move_type
            if (detail.known_move_type?.url) {
                await this.$http.get(detail.known_move_type.url)
                .then(res => {
                    const nameObj = res.data.names.find(name => name.language.name === "ko")
                    result.push(`${nameObj ? nameObj.name : ''} 타입 기술을 배운 상태에서`)
                })
            }

            // location
            if (detail.location?.name) {
                const locationMap = {
                    'mt-coronet': '천관산',
                    'chargestone-cave': '전기돌동굴',
                    'kalos-route-13': '칼로스 13번 도로',
                    'eterna-forest': '영원의숲 이끼바위 근처',
                    'pinwheel-forest': '바람개비숲',
                    'kalos-route-20': '칼로스 20번 도로', 
                    'sinnoh-route-217': '신오 217번 도로 얼음바위 근처',
                    'twist-mountain': '태엽산',
                    'frost-cavern': '프로스트케이브',
                    'mount-lanakila': '라나키라마운틴'
                }
                
                const locationName = locationMap[detail.location.name]
                if (locationName) {
                    result.push(`${locationName}에서`)
                }
            }

            // min_affection
            if (detail.min_affection !== null) {
                result.push(`절친 하트가 ${detail.min_affection} 이상일 때`)
            }

            // min_beauty
            if (detail.min_beauty !== null) {
                result.push(`아름다움이 ${detail.min_beauty} 이상일 때`)
            }

            // min_happiness
            if (detail.min_happiness !== null) {
                result.push(`친밀도가 ${detail.min_happiness} 일 때`)
            }

            // min_level
            if (detail.min_level !== null) {
                result.push(`레벨이 ${detail.min_level} 이상일 때`)
            }

            // needs_overworld_rain
            if (detail.needs_overworld_rain === true) {
                result.push('비 오는 날씨에')
            }

            // party_species
            if (detail.party_species?.name) {
                await this.$http.get(detail.party_species.url)
                .then(res => {
                    const nameObj = res.data.names.find(name => name.language.name === "ko")
                    result.push(`${nameObj ? nameObj.name : ''} 포켓몬을 소지한 상태에서`)
                })
            }

            // party_type
            if (detail.party_type?.name) {
                await this.$http.get(detail.party_type.url)
                .then(res => {
                    const nameObj = res.data.names.find(name => name.language.name === "ko")
                    result.push(`소지한 포켓몬에 ${nameObj ? nameObj.name : ''} 타입이 있을 때`)
                })
            }

            // relative_physical_stats
            if (detail.relative_physical_stats !== null) {
                const statsMap = {
                    '-1': '공격과 방어가 같으면',
                    '0': '방어가 공격보다 높으면',
                    '1': '공격이 방어보다 높으면'
                }
                result.push(statsMap[detail.relative_physical_stats])
            }

            // time_of_day
            if (detail.time_of_day) {
                const timeMap = {
                    'day': '낮에',
                    'night': '밤에'
                }
                result.push(timeMap[detail.time_of_day])
            }

            // trade_species 처리를 위한 별도의 메서드 호출
            if (detail.trade_species?.url) {
                await this.fetchTradeSpecies(detail.trade_species.url)
                .then(koreanName => {
                    if (koreanName) {
                        result.push(`상대 ${koreanName}(으)로`)
                    }
                })
            }

            // turn_upside_down 조건 추가
            if (detail.turn_upside_down === true) {
                result.push("현재 조작하고 있는 기기의 위아래를 거꾸로 잡은 상태에서")
            }

            // trigger에 따른 기본 문구 추가
            const triggerMap = {
                'level-up': '레벨업으로',
                'trade': '통신교환으로',
                'use-item': '사용하여',
                'shed': '몬스터볼을 가지고 있는 상태에서\n포켓몬 슬롯이 1자리 이상 비어 있을 때\n토중몬 진화 시 빈 슬롯에 획득하며',
                'spin': '사탕공예를 지니게 하고 L스틱으로\n캐릭터를 계속 회전시키면',
                'tower-of-darkness': '악의 탑을 공략해 악의 족자를 보여줌(8세대) 악의 족자를 사용(9세대)',
                'tower-of-waters': '물의 탑을 공략해 물의 족자를 보여줌(8세대) 물의 족자를 사용(9세대)',
                'three-critical-hits': '한 전투에서 급소를 3번 맞히면',
                'take-damage': '기절하지 않고 49 이상의 누적 데미지를 입은 후\n모래먼지구덩이의 고인돌 아래를 지나가면',
                'other': '상의 필요',
                'agile-style-move': '배리어러시를 속공으로 20번 사용',
                'strong-style-move': '독침천발을 강공으로 20번 사용',
                'recoil-damage': '누적 반동 대미지 294 이상 입은 상태에서 레벨업'
            }

            if (detail.trigger?.name) {
                result.push(triggerMap[detail.trigger.name] || detail.trigger.name)
            }

            return result.join(', ') + " 진화"
        },

        // trade_species를 위한 별도의 async 메서드
        async fetchTradeSpecies(url) {
            try {
                const response = await this.$http.get(url)
                console.log("trade_species", response)
                return response.data.names.find(name => name.language.name === "ko")?.name
            } catch (error) {
                console.error('Error fetching trade species:', error)
                return null
            }
        },

        replaceTextToImg(text) {
            if (!text) {
                return text
            }

            const baseUrl = process.env.NODE_ENV === 'production' 
                ? 'https://smartnuo.com'
                : 'http://localhost:8081'

            let result = text
            this.textList.forEach(item => {
                if (result.includes(item)) {
                    // #을 제거한 문자열 생성
                    const itemWithoutHash = item.replace('#', '')
                    
                    // 이미지 태그 수정
                    const imgTag = `<img 
                        height="14" 
                        style="
                            vertical-align: middle;
                            display: inline-block;
                            position: relative;
                            top: -2px;
                            margin: 0 2px;
                        " 
                        src="${baseUrl}/upload/evolution/${itemWithoutHash}.png" 
                        title="${itemWithoutHash}"></img>`
                    result = result.replaceAll(item, imgTag)
                }
            })
            
            return result
        }
    }
}
</script>
<style scoped>
.docs_table {
    width: calc(100% - 2px);
    margin:0 1px;
    height:100%;
    border-collapse: collapse;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #d9d9d9;
}

::v-deep .docs_table th {
    height: 25px;
    font-weight: 500;
    padding-bottom: 2px;
    background:#D9D9D9 !important;
    font-size: 10px;
}

/* .version_group_select > ▼ 아이콘 (Mobile 브라우저) */
@media (hover: none) and (pointer: coarse) {
    ::v-deep .docs_table th {
        height: 25px;
        font-weight: 500;
        padding-bottom: 0px !important;
        background:#D9D9D9 !important;
        font-size: 10px;
    }
}

::v-deep .docs_table td {
    border-right: 1px solid #d9d9d9;
    padding: 4px;
    font-size: 10px;
    font-weight: 400;
}

/* .version_group_select > ▼ 아이콘 (Mobile 브라우저) */
@media (hover: none) and (pointer: coarse) {
    ::v-deep .docs_table td {
        border-right: 1px solid #d9d9d9;
        padding: 4px;
        font-size: 10px;
        font-weight: 400;
        padding-bottom: 2px !important;
    }
}

::v-deep .docs_table td:first-child {
    width: 120px;
}

::v-deep .docs_table td:last-child {
    border-right: none;
}

.docs_table2 {
    width: calc(100% - 2px);
    margin:0 1px;
    height:100%;
    border-collapse: collapse;
    border-radius: 8px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #d9d9d9;
}

::v-deep .docs_table2 th {
    height: 25px;
    font-weight: 500;
    padding-bottom: 2px;
    background:#D9D9D9 !important;
    font-size: 10px;
}

::v-deep .docs_table2 td {
    border-right: 1px solid #d9d9d9;
    padding: 4px;
    height: 23px;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
}

/* .version_group_select > ▼ 아이콘 (Mobile 브라우저) */
@media (hover: none) and (pointer: coarse) {
    ::v-deep .docs_table2 td {
        border-right: 1px solid #d9d9d9;
        padding: 4px;
        height: 23px;
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        padding-bottom: 2px !important;
        border-bottom: 1px solid #d9d9d9;
    }
}


::v-deep .docs_table2 td:first-child {
    width: 120px;
}

::v-deep .docs_table2 td:last-child {
    border-right: none;
}

::v-deep .docs_pokemon_autocomplete .v-text-field .v-input__slot {
    min-height: 40px !important;
    height: 40px !important;
}

::v-deep .docs_pokemon_autocomplete .v-text-field .v-input__icon--append .v-icon {
    font-size: 26px;
    margin-top: -2px;
}
</style>